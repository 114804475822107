import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ProfileSection from '../../ProfileSection';
import { CommonContext } from '../../../store/context/commonContext';
import { Divider, FormGroup, InputAdornment, ListItemIcon, MenuItem, Select, TextField } from '@mui/material';
import * as Icon from '@mui/icons-material';
import { empty } from '../../../utils/common';
import AuthPopup from '../../../commonComponents/authComponents/AuthPopup';
import { UserAuth } from '../../../store/context/userContext';
import { useEffect, useState } from 'react';
import config from '../../../config';
import SearchService from '../../../services/search';
import LandingPageDrawer from '../../LandingPageLayout/Sidebar';
import { DEFAULT_LOGO_SIZE, SMALL_LOGO_SIZE } from '../../../commonComponents/CommonConstants/commonKeys';


const drawerWidth = 267;

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - 0px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	position: 'fixed', // Make the position fixed
	top: 0, // Position at the top of the viewport
}));


const Header = (props) => {
	const { user, authPopup, setAuthPopup } = UserAuth()
	const navigate = useNavigate()
	const openAuthPopup = (type) => {
		setAuthPopup({ ...authPopup, type: type, open: true })
	}
	const closeAuthPopup = () => {
		setAuthPopup({ ...authPopup, open: false })
	}
	const { isNineHundred, isLoginHamburger, setShowSearchType, isTablet, setValue, toggleNavigationDrawer, clearFiltersToggle } = CommonContext();

	const location = useLocation();

	const [activityOptions, setActivityOptions] = useState({})
	const [isActivityOptionsLoaded, setIsActivityOptionsLoaded] = useState(false)
	const newSearchParams = new URLSearchParams(window.location.search);
	const [searchKw, setSearchKw] = useState(newSearchParams.get('kw') === null ? '' : newSearchParams.get('kw'));


	const handleClearSearch = (e) => {
		setSearchKw('')
		document.activeElement.blur(); // Blur the active element to dismiss the keyboard


		// Below code should be uncommented if OnChange is implemented.


		setSearchFilters({ 'kw': '' });
		const newSearchParams = new URLSearchParams(window.location.search);
		newSearchParams.set('kw', '');
		const newUrl = `${window.location.pathname}?${newSearchParams.toString()}`;

		// Merge existing searchFilters with the updated parameter
		const updatedFilters = { ...searchFilters, kw: '' };

		// Update URL without removing searchFilters
		window.history.pushState({ searchFilters: updatedFilters }, '', newUrl);
	};

	// useEffect(() => {
	// 	if (searchKw !== '') {
	// 		handleClearSearch()
	// 	}
	// }, [clearFiltersToggle]) // commented it out, as it was creating issue when user type and hit enter or click on search

	const handleChangeActivity = (e) => {
		const fullValue = e.target.value;
		let activityValue = '';
		let type = '';

		if (fullValue.includes('-')) {
			// If it's a specific activity, extract the part after the hyphen
			activityValue = fullValue.split('-')[1];
		} else if (fullValue !== 'A') {
			// If it's a category (access or experience), use the full value
			activityValue = fullValue;
		}

		if (activityValue === 'access' || activityValue === 'F' || activityValue === 'H' || activityValue === 'BW' || activityValue === 'FR') {
			type = 'access';
			setShowSearchType('access');
			setValue(0);
		} else if (activityValue === 'experience' || activityValue === 'GT' || activityValue === 'OA' || activityValue === 'RE' || activityValue === 'IP') {
			type = 'experience';
			setShowSearchType('experience');
			setValue(1);
		} else if (activityValue === 'academy') {
			type = 'academy';
			setShowSearchType('academy');
			setValue(2);
		}
		// Update search filters
		// setSearchFilters({ activity: activityValue, kw: '' }); this was before below code block of switch(type)


		switch (type) { // Added this code block to remove filters when switched between access to experience from drop down
			case 'access':
				setSearchFilters({ activity: activityValue, kw: '', eat: '', mnd: '', mxd: '', mnp: '', mxp: '', sort: 'R', d1: '', d2: '' });
				break;
			case 'experience':
				setSearchFilters({ activity: activityValue, kw: '', species: '', sort: 'R', d1: '', d2: '' });
				break;
			default:
				break;
		}

		// Create new URLSearchParams with updated values
		const newSearchParams = new URLSearchParams(window.location.search);
		newSearchParams.set('type', type);
		newSearchParams.set('at', activityValue);
		newSearchParams.set('kw', '');
		setSearchKw('')

		// Construct the new URL with updated search parameters
		const newUrl = `${window.location.pathname}?${newSearchParams.toString()}`;

		// Merge existing searchFilters with the updated parameter
		const updatedFilters = { ...searchFilters, activity: activityValue };

		// Update URL without removing searchFilters
		window.history.pushState({ searchFilters: updatedFilters }, '', newUrl);
	};


	const handleChangeSearch = (e) => {
		setSearchKw(e.target.value)
	};

	const notShowSidebarPaths = ['/checkout', '/search', '/package', '/property', '/payment', '/experience']

	const NotShowSidebar = !notShowSidebarPaths.some(path => location.pathname === path || location.pathname.startsWith(path + '/'));

	const { searchFilters, setSearchFilters, screenWidth } = CommonContext()

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (e) => {
		setAnchorEl(e.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const isGuestorLister = config.module === 'guest' ? '' : '/landowner'
	const logoLink = window.location.origin + isGuestorLister + '/assets/images/farwide-new-logo.svg'
	const logoLinkMobile = window.location.origin + isGuestorLister + '/assets/images/farwide-new-logo-white-icon.svg'

	// console.log(logoLink)

	useEffect(() => {
		SearchService.getAllActivitiesDetails((data) => {
			const filteredData = Object.fromEntries(
				Object.entries(data).filter(([key]) => key !== 'academy')
			);
			setActivityOptions(filteredData)
			setIsActivityOptionsLoaded(true);

			// Only set the initial activity after options are loaded
			if (!searchFilters.activity) {
				setSearchFilters(prevFilters => ({ ...prevFilters, activity: 'access' }));
			}
		})
	}, [])

	const [showNavigationSidebar, setShowNavigationSidebar] = useState(true)

	const exactPaths = ['/checkout', '/search', '/package', '/property', '/payment', '/experience', '/ourstory', '/conservationtrust']
	const userPaths = ['/dashboard', '/bookings', '/booking', '/chat', '/wishlist', '/transactions', '/transactiondetails', '/account', '/reviewsuccefull', '/review', '/experiencereview']

	useEffect(() => {
		if (exactPaths.some(path => location.pathname === path || location.pathname.startsWith(path + '/'))) {
			setShowNavigationSidebar(true)
		} else if (userPaths.some(path => location.pathname === path || location.pathname.startsWith(path + '/')) && screenWidth <= 900) {
			setShowNavigationSidebar(false)
		} else if (userPaths.some(path => location.pathname === path || location.pathname.startsWith(path + '/')) && screenWidth > 900) {
			setShowNavigationSidebar(true)
		}
	}, [screenWidth, location.pathname])

	const [firstload, setFirstLoad] = useState(true)

	useEffect(() => {
		if (!firstload) {
			// console.log(searchFilters.activity);
			setSearchKw('')
		}
		setFirstLoad(false)

	}, [searchFilters.activity])


	return (
		<AppBar className='fw_appbar' sx={{ background: '#fff' }} position="absolute" open={props.open}>
			<LandingPageDrawer />

			<Toolbar
				sx={{
					height: "72px"
				}}
			>
				<Box sx={{ mr: 'auto', display: 'flex', alignItems: 'center', width: location.pathname === '/dashboard' ? '100%' : 'unset !important' }}>
					{NotShowSidebar &&
						<IconButton
							className='fw_hdr_toggle_btn inside_humburger'
							edge="start"
							aria-label="open drawer"
							onClick={props.toggleDrawer}
							sx={{
								height: '40px',
								width: '40px',
								marginRight: '20px',
								marginLeft: '-4px',
								color: 'var(--black)',
								...(props.open),
								display: { md: 'block' },
								backgroundColor: props.open ? 'white' : 'rgba(0, 0, 0, 0.04)'

							}}
						>
							<MenuIcon />
						</IconButton>
					}
					{
						showNavigationSidebar &&
						< Box sx={{}}>
							{/* {empty(user) &&
							isTablet && ( */}
							<>
								<Box sx={{ color: 'var(--black)' }}>
									<IconButton
										id="basic-button"
										aria-controls={open ? 'basic-menu' : undefined}
										aria-haspopup="true"
										aria-expanded={open ? 'true' : undefined}
										onClick={toggleNavigationDrawer(true)}
										sx={{
											// marginRight: 2
										}}
									>
										<Icon.MenuOutlined />
									</IconButton>
								</Box>
							</>
						</Box>
					}
					<Link to="/">
						<Box className="fw_logo" sx={{ display: isNineHundred ? 'none' : 'block' }}><img src={logoLink} alt='FarWide' style={{ ... (isTablet ? SMALL_LOGO_SIZE : DEFAULT_LOGO_SIZE), display: 'block' }} /></Box>
					</Link>
					<Link to="/"
						style={{
							marginInline: (location.pathname === '/dashboard' && screenWidth <= 480) ? 'auto' : 'unset !important' // This condition added to center logo on dashboard page
						}}
					>
						<Box className="fw_logo_mob" sx={{ display: !isNineHundred ? 'none' : 'block', width: '40px' }}><img src={logoLinkMobile} alt='FarWide' style={{ display: 'block' }} /></Box>
					</Link>
				</Box>
				<Box sx={{ margin: 'auto' }}>
					{
						location.pathname !== '/dashboard' &&
						<FormGroup sx={{
							alignItems: 'center', flexWrap: 'nowrap', flexDirection: 'row', justifyContent: 'center', border: 'solid 1px #C5C5C7', borderRadius: '6px', m: 'auto', ml: 2, mr: 2, height: '48px',
							"&:hover": {
								"& .MuiSvgIcon-root": {
									color: 'var(--black)',
								},
							},
						}}>
							<Select
								name="listing_sort"
								size="large"
								value={isActivityOptionsLoaded ? (searchFilters.activity || '') : ''}
								onChange={handleChangeActivity}
								IconComponent={Icon.KeyboardArrowDownOutlined}
								renderValue={(selected) => {
									if (!isActivityOptionsLoaded) return '';

									for (const [key, category] of Object.entries(activityOptions)) {
										if (key === selected) {
											return category.label;
										}
										for (const [activityKey, activity] of Object.entries(category.activities)) {
											if (activityKey === selected) {
												return activity.label;
											}
										}
									}
									return '';
								}}
								sx={{
									border: 'none',
									"& fieldset": { border: 'none' },
									m: 0,
									pt: 0,
									mb: 0,
									mt: "0px",
									display: isNineHundred ? 'none' : 'block',
									'& .MuiSelect-select': { minWidth: '100px' }
								}}
							>
								{isActivityOptionsLoaded && Object.entries(activityOptions).map(([key, category]) => [
									<MenuItem
										key={key}
										value={key}
										sx={{
											color: '#1B1B1B',
										}}
									>
										{category.label}
									</MenuItem>,
									...Object.entries(category.activities).map(([activityKey, activity]) => (
										<MenuItem
											key={`${key}-${activityKey}`}
											value={activityKey}
											sx={{ pl: 2, color: '#1B1B1B' }}
										>
											<ListItemIcon sx={{ minWidth: 'unset !important' }}>
												<Icon.Circle sx={{ color: '#C5C5C7', fontSize: '7px', minWidth: 'unset !important' }} />
											</ListItemIcon>
											{activity.label}
										</MenuItem>
									))
								])}
							</Select>
							<Divider orientation="vertical" variant="middle" flexItem sx={{ mt: 0, mb: 0, display: { xs: 'none', sm: 'none', md: 'block' } }} />
							<TextField
								className='top_hdr_search_input'
								placeholder={isNineHundred ? 'Search..' : 'Where would you like to go?'}
								variant="outlined"
								autoComplete="off" //Added this to avoid password and email or any suggestions by browser
								InputProps={{
									endAdornment: (
										<InputAdornment position="end" style={{ position: 'absolute', right: '2px' }}>
											{
												(searchKw && searchKw !== '') &&
												<IconButton sx={{ width: "34px", height: "34px", p: 0, mr: '2px', color: 'var(--black)', backgroundColor: 'var(--grey300)' }} onClick={handleClearSearch}>
													<Icon.Close />
												</IconButton>
											}
											<IconButton variant="contained" style={{ color: empty(searchFilters.kw) ? "#C5C5C7" : "var(--black)", padding: '5px' }} onClick={() => {

												// If onchange api call is required lator, then move below code in onChange function. 
												setSearchFilters({ 'kw': searchKw })
												const newSearchParams = new URLSearchParams(window.location.search);
												newSearchParams.set('kw', searchKw);
												const newUrl = `${window.location.pathname}?${newSearchParams.toString()}`;

												// Merge existing searchFilters with the updated parameter
												const updatedFilters = { ...searchFilters, kw: searchKw };

												// Update URL without removing searchFilters
												window.history.pushState({ searchFilters: updatedFilters }, '', newUrl);
												// till above live

												navigate("/search?kw=" + searchKw + "&at=" + (searchFilters.activity === "A" ? '' : searchFilters.activity) + "&type=" + (searchFilters.activity === "A" ? '' : searchFilters.activity))
												document.activeElement.blur(); // Blur the active element to dismiss the keyboard

											}}>
												<Icon.SearchOutlined />
											</IconButton>
										</InputAdornment>
									),
									autoComplete: 'off' //Added this to avoid password and email or any suggestions by browser
								}}
								sx={{
									border: 'none', "& fieldset": { border: 'none' }, m: 0, pt: '2px', pb: '2px', maxWidth: '520px',
									'& .MuiOutlinedInput-root': {
										paddingRight: '2px',
										"& .MuiInputBase-input": {
											padding: '11.5px 72px 11.5px 8px',
											borderRadius: 0,
											minWidth: !isNineHundred && '30ch',
										}
									}
								}
								}
								size='large'
								value={searchKw}
								onInput={handleChangeSearch}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										console.log(searchKw);
										// If onchange api call is required lator, then move below code in onChange function. 
										setSearchFilters({ 'kw': searchKw })
										const newSearchParams = new URLSearchParams(window.location.search);
										newSearchParams.set('kw', searchKw);
										const newUrl = `${window.location.pathname}?${newSearchParams.toString()}`;

										// Merge existing searchFilters with the updated parameter
										const updatedFilters = { ...searchFilters, kw: searchKw };

										// Update URL without removing searchFilters
										window.history.pushState({ searchFilters: updatedFilters }, '', newUrl);
										// till above live

										navigate("/search?kw=" + searchKw + "&at=" + (searchFilters.activity === "A" ? '' : searchFilters.activity) + "&type=" + (searchFilters.activity === "A" ? '' : searchFilters.activity))
										document.activeElement.blur(); // Blur the active element to dismiss the keyboard
									}
								}}

							>
							</TextField>
						</FormGroup>
					}
				</Box>
				<Box sx={{ ml: 'auto' }}>
					{
						empty(user) ?
							<>
								{
									!isLoginHamburger &&
									<Box>
										<Button variant="outlined" sx={{ mr: 2 }} onClick={() => { openAuthPopup('signin') }}>Sign In</Button>
										<Button variant="contained" onClick={() => { openAuthPopup('signup') }}>Sign Up</Button>
									</Box>

								}
								<AuthPopup open={authPopup.open} type={authPopup.type} handleClose={() => { closeAuthPopup(false) }} handleOpen={openAuthPopup} />
							</> :
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<ProfileSection />
							</Box>
					}
				</Box>
			</Toolbar>
		</AppBar >)
}

export default Header
