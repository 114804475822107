import { Box, Drawer, Skeleton, Typography, styled } from "@mui/material";
import MessagesDashboard from "./MessagesDashboard";
import ShowMessages from "./ShowMessages";
import { useEffect, useState } from "react";
import Profile from "./Profile";
import { UserAuth } from "../../store/context/userContext";
import { MessagesApi } from "../../services/messagesApi";
import { useLocation, useNavigate, useParams } from "react-router";
import { exponentialBackoff, extractDataFromArray } from "../../utils/common";
import { MessageContext } from "../../store/context/messageContext";
import config from "../../config";

const drawerWidth = 327;
const drawerWidth2 = 252;



const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        flexGrow: 1,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    })
);

const Messages = () => {

    const [isChatHistoryAvailable, setIsChatHistoryAvailable] = useState({})
    const [openMessageDrawer, setOpenMessageDrawer] = useState(true);
    const [openInfoDrawer, setOpenInfoDrawer] = useState(false);
    const [connections, setConnections] = useState([]);
    const { user } = UserAuth()
    const location = useLocation();
    const navigate = useNavigate();

    const { isMobile, setIsMessageDashboard, isMessagesDashboard, isShowMessages, setIsShowMessages, isProfile, start, userData, detail, setDetail, setSeqValue, setuserDetail, heightVariable, userType, setIsProfile, listerSeqForAdmin } = MessageContext();
    const [active, setActive] = useState();
    const param = useParams();
    const [limit, setLimit] = useState();
    const [userMessages, setUserMessages] = useState([])
    const [userMessageLastSeqValue, setUserMessageLastSeqValue] = useState('')
    const [newMessageRecivedlastSeq, setNewMessageRecivedlastSeq] = useState();
    const [resetScroll, setResetScroll] = useState(false)
    const [loadingUser, setLoadingUser] = useState(true);
    const [loadingChatPage, setloadingChatPage] = useState(true)
    const [search, setSearch] = useState(null);
    const [toggleNewMessage, setToggleNewMessage] = useState()
    const [isComingFromContactPage, setIsComingFromContactPage] = useState(false);
    const [isInputClicked, setIsInputClicked] = useState(false)
    const [paginationConfig, setConfig] = useState({
        start: 0,
        limit: ""
    });

    useEffect(() => {
        setIsMessageDashboard(true)
        setIsShowMessages(false)
        MessagesApi.getAllMessages(search, paginationConfig.limit, paginationConfig.start, (data) => {

            setConnections([...data])

        }, (error) => {
            // console.log(error)
        });
        if (location.search.includes('?contact')) {
            setIsComingFromContactPage(true)
        } else {
            setIsComingFromContactPage(false)
        }
        // console.log("location", location);
    }, [])

    // CHAT AVAILABLE LOGIC STARTS
    useEffect(() => {
        setloadingChatPage(true)
        MessagesApi.isChatHistory((res) => {
            setIsChatHistoryAvailable(res)
            setloadingChatPage(false)
        })

        setSeqValue(extractDataFromArray(user, ['seq'], extractDataFromArray(user, ['user_seq'], 0)))

    }, []);



    let chatAvailabe = isChatHistoryAvailable.isChatHistoryAvailable

    // CHAT AVAILABLE LOGIC ENDS


    // FETCH USER INFO LOGIC STARTS


    useEffect(() => {
        if (config.module === 'admin') { // Added it to get userInfo in admin portal

            MessagesApi.getUserInfo(userData.u_seq, "G", (res) => {

                setDetail(res)
                setuserDetail(res.data)
                setLoadingUser(false)
            }, (error) => {
                // console.log(error)
                setLoadingUser(false)
            })
        } else { // this is for guest and landowner
            MessagesApi.getUserInfo(userData.u_seq, userType, (res) => {
                setDetail(res)
                setuserDetail(res.data)
                setLoadingUser(false)
            }, (error) => {
                // console.log(error)
                setLoadingUser(false)
            })
        }

    }, [param, userType]);

    // FETCH USER INFO LOGIC ENDS


    // FETCH USER MESSAGE LOGIC STARTS


    const fetchUserMessages = async (userData) => {
        // console.log("Inside fetch user messages");

        let userType1 = '';

        if (!Array.isArray(connections) || !param) {
            // console.log("Inside fetch user messages second");
            userType1 = '';
            return;
        }
        // console.log("Inside fetch user messages third");

        // Find the matching conversation object
        const matchingConnection = connections?.find(connection =>
            connection?.conversation && connection?.conversation?.seq === userData
        );

        // Check if the matching connection has type "A"
        if (matchingConnection && matchingConnection?.conversation) {
            userType1 = matchingConnection?.conversation?.type === "A" ? "A" : "";
        } else {
            userType1 = '';
        }


        if (config.module === 'admin') { // This code block is used to get userMessages when chat page loads first time in Admin

            // console.log("lister seq for admin", listerSeqForAdmin);

            function getValidListerSeq() {
                // Assume listerSeqForAdmin is globally defined or accessible in context
              
                // Get URL search parameters
                const searchParams = new URLSearchParams(window.location.search);
                const urlListerSeq = searchParams.get('listerSeq');
              
                let validListerSeq;
              
                if (listerSeqForAdmin) {
                  // Compare both if present
                  validListerSeq = urlListerSeq && listerSeqForAdmin !== urlListerSeq 
                    ? listerSeqForAdmin 
                    : listerSeqForAdmin;
                } else {
                  // Use URL value if admin value is not present
                  validListerSeq = urlListerSeq || null;
                }
              
                return validListerSeq;
              }
              
              // Example Usage
              const listerSeq = getValidListerSeq();
            // console.log("param", param);

            exponentialBackoff(() => {

                return listerSeq !== ''
            }, 5, 1000, () => {
                // console.log("got lister seq", listerSeqForAdmin);

                // Determine which seq value to use
                let seqToUse;
                if (userData && param?.seq) {
                    // If both values exist, only use if they match
                    seqToUse = userData === param.seq ? userData : null;
                } else {
                    // If only one exists, use that one
                    seqToUse = userData || param?.seq || null;
                }

                MessagesApi.getUserMessages(seqToUse, limit, start, listerSeq, (res) => {
                    setUserMessageLastSeqValue(res[0].seq)

                    setUserMessages(res)
                    if (res?.length > 0) {

                        setNewMessageRecivedlastSeq(res[1].seq)
                    }
                }, (error) => {
                    // console.log(error)
                })
            })
        } else {

            // This code block loads messages of first user in guest and landowner when chat page opens first time

            // console.log("NOT ADMIN");

            await MessagesApi.getUserMessages(userData, limit, start, userType1, (res) => {
                setUserMessageLastSeqValue(res[0].seq)

                setUserMessages(res)
                if (res?.length > 0) {

                    setNewMessageRecivedlastSeq(res[1].seq)
                }
            }, (error) => {
                // console.log(error)
            })
        }

    }
    // FETCH USER MESSAGE LOGIC ENDS
    // console.log("isComingFromContactPage", isComingFromContactPage);


    useEffect(() => {

        if (isMobile) {
            // Set scroll position to 0 when the chat page loads
            // console.log('Chat page loaded. Setting scrollY to 0.');
            window.scrollTo(0, 0);

            // Disable scrolling on the body and html elements
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';  // Ensure html is non-scrollable
            // console.log('Body and HTML overflow set to hidden. Scrolling disabled.');

            // Monitor scroll positions of the window, body, and html
            const enforceScrollTop = () => {
                // console.log("1111111");
                // console.log("enforceScrollTop isInputClicked", isInputClicked);
                if (isInputClicked === true) {
                    // console.log("isInputClicked 111 : - ", isInputClicked);
                    return
                } else if ((window.scrollY !== 0 || document.documentElement.scrollTop !== 0) && isInputClicked === false) {
                    // console.log("isInputClicked 222 : - ", isInputClicked);
                    // console.log('Scroll position changed! Resetting scroll to 0.');
                    window.scrollTo(0, 0);
                    document.documentElement.scrollTop = 0; // Reset scrollTop if it changes
                }
            };

            // Allow pull-to-refresh while ensuring scroll stays at the top
            const preventScrollOnTop = () => {
                // console.log('Checking scroll positions...');
                enforceScrollTop(); // Enforce scroll back to 0 if it changes
            };

            if (isInputClicked === true) {
                // console.log('Calling enforceScrollTop as isInputClicked is : - ', isInputClicked);
                enforceScrollTop(); // Enforce scroll back to 0 if it changes
            }

            // Attach scroll event to ensure the scroll is reset if it changes
            window.addEventListener('scroll', preventScrollOnTop);
            document.addEventListener('touchmove', preventScrollOnTop, { passive: true });

            // Cleanup function to re-enable scrolling when navigating away from chat
            return () => {
                // console.log("Clean up");
                document.body.style.overflow = '';  // Re-enable scrolling for other pages
                document.documentElement.style.overflow = '';  // Re-enable scrolling on html
                window.removeEventListener('scroll', preventScrollOnTop);
                document.removeEventListener('touchmove', preventScrollOnTop);
                // console.log('Cleanup complete. Scrolling restored.');
            };
        }



    }, [isInputClicked, isMobile]);  // Empty dependency array ensures it runs only on mount and unmount

    // Add below navigation handler logic to solve issue when browser back button is clicked
    useEffect(() => {

        const handleBackButton = (e) => {
            // if (window.location.pathname === '/chat') {
            // console.log("444");
            // Update all states as specified
            setIsMessageDashboard(true);
            setIsShowMessages(false);
            setIsProfile(false);
            setIsComingFromContactPage(false);
            navigate("/chat", { replace: true });
            // }
        };

        window.addEventListener('popstate', handleBackButton);

        // Push initial state when component mounts
        if (config.module !== 'admin') {

            window.history.pushState(null, null, window.location.pathname);
        }

        return () => {
            // console.log("333");
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [navigate, setIsMessageDashboard, setIsShowMessages, setIsProfile, setIsComingFromContactPage]);


    useEffect(() => { // Using this to load messages in admin portal when user refreshes page

        // console.log("before calling fetchhh", listerSeqForAdmin);
        if (listerSeqForAdmin !== '') {
            // console.log("calling fetchhh", listerSeqForAdmin);

            fetchUserMessages()
        }
    }, [listerSeqForAdmin])
    return (
        <Box
            className="CHAT WRAPPER"
            sx={
                isMobile
                    ? {
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        borderRadius: 2.25,
                        backgroundColor: "white",
                        position: "relative",
                        // height: 'calc(100vh - 113px)' // 100vh change to 100svh for mobile view fix safari
                        height: 'calc(100svh - 188px)' // Changed 113px to 188px after footer was added
                    }
                    : {
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        borderRadius: 2.25,
                        backgroundColor: "white",
                        position: "relative",
                        // height: 'calc(100vh - 72px)'
                    }
            }
        >
            {
                !isComingFromContactPage &&
                <Box
                    sx={
                        isMobile && isMessagesDashboard
                            ? {
                                textAlign: "left",
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }
                            : isMobile && !isMessagesDashboard
                                ? {
                                    textAlign: "left",
                                    display: "none",
                                    width: "100%",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }
                                : { textAlign: "left" }
                    }
                >
                    <Typography
                        variant={isMobile ? "h3" : "h4"}
                        sx={
                            isMobile
                                ? { marginTop: "-5px", fontWeight: "bold" }
                                : { fontWeight: "bold" }
                        }
                    >
                        Messages
                    </Typography>

                </Box>
            }

            {/* Changed height 196px to 263px then to 290px (Before Dec 9 2024) after footer was added then to 192px for messages not showing on smaller desktop devices (Dec 9 2024) */}
            {isMobile ? (loadingChatPage && <Skeleton variant="rectangular" sx={{ borderRadius: theme => `${theme.card.borderRadius}`, height: heightVariable, width: "100%" }} />) :
                (

                    loadingChatPage && <Box sx={{
                        display: "flex", justifyContent: "center", alignItems: "center", gap: 2, flexShrink: 0,
                    }}>
                        <Skeleton variant="rectangular" sx={{ borderRadius: theme => `${theme.card.borderRadius}`, height: heightVariable, width: 327 }} />
                        <Skeleton variant="rectangular" sx={{ borderRadius: theme => `${theme.card.borderRadius}`, height: heightVariable, width: 600 }} />
                    </Box>
                )
            }

            {!loadingChatPage && (
                !chatAvailabe ?
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 413,
                            width: "100%",
                            borderRadius: 2,
                            backgroundColor: "#F5F5F6",
                            margin: isMobile ? 0 : "1.438rem",
                            gap: 4,
                            paddingInline: 1.5
                        }}
                    >
                        <img
                            src={"chat_image.svg"}
                            alt="message"
                            width={277}
                            height={153}
                        />
                        <Typography variant="body1" color="var(--greyText)" sx={{ textAlign: 'center' }} >{config.module === 'lister' ? 'Guest messages and inquiries will show up here once your listing is active.' : 'You don’t have any conversations yet.'}</Typography>
                    </Box>
                    :
                    <Box
                        sx={
                            isMobile
                                ? {
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 4,
                                }
                                : {
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 4,
                                    flex: 1,
                                }
                        }
                    >
                        {
                            (isMessagesDashboard && isMobile) || (connections.length > 0 && isMobile && !isShowMessages && isMessagesDashboard) ? (

                                <Drawer
                                    sx={
                                        isMobile
                                            ? {
                                                flexShrink: 0,
                                                transition: "transform 0.5s ease-in-out",
                                                transform: openMessageDrawer
                                                    ? "translateX(0)"
                                                    : `translateX(1000px)`,
                                                "& .MuiDrawer-paper": {
                                                    boxSizing: "border-box",
                                                    position: "relative",
                                                    border: "none",
                                                },
                                                display: openMessageDrawer ? "block" : "none",
                                            }
                                            : {
                                                width: drawerWidth,
                                                flexShrink: 0,
                                                transition: "transform 0.5s ease-in-out",
                                                transform: openMessageDrawer
                                                    ? "translateX(0)"
                                                    : `translateX(1000px)`,
                                                "& .MuiDrawer-paper": {
                                                    width: drawerWidth,
                                                    boxSizing: "border-box",
                                                    position: "relative",
                                                    border: "none",
                                                },
                                                display: openMessageDrawer ? "block" : "none",
                                            }
                                    }
                                    variant={isMobile ? "permanent" : "permanent"}
                                    open={openMessageDrawer}
                                    anchor="left"
                                >
                                    {
                                        !isComingFromContactPage ?

                                            <MessagesDashboard
                                                setResetScroll={setResetScroll}
                                                fetchUserMessages={fetchUserMessages}
                                                setSearch={setSearch}
                                                search={search}
                                                open={openMessageDrawer}
                                                connections={connections}
                                                setConnections={setConnections}
                                                active={active}
                                                setActive={setActive}
                                                setToggleNewMessage={setToggleNewMessage}
                                                toggleNewMessage={toggleNewMessage}
                                                loadingUser={loadingUser}
                                                setLoadingUser={setLoadingUser}
                                                isComingFromContactPage={isComingFromContactPage}
                                            /> :

                                            <Main open={openInfoDrawer}>
                                                <ShowMessages
                                                    loadingUser={loadingUser}
                                                    resetScroll={resetScroll}
                                                    userMessageLastSeqValue={userMessageLastSeqValue}
                                                    newMessageRecivedlastSeq={newMessageRecivedlastSeq}
                                                    setUserMessages={setUserMessages}
                                                    fetchUserMessages={fetchUserMessages}
                                                    userMessages={userMessages}
                                                    setOpenMessageDrawer={setOpenMessageDrawer}
                                                    openMessageDrawer={openMessageDrawer}
                                                    setOpenInfoDrawer={setOpenInfoDrawer}
                                                    openInfoDrawer={openInfoDrawer}
                                                    connections={connections}
                                                    setConnections={setConnections}
                                                    setToggleNewMessage={setToggleNewMessage}
                                                    toggleNewMessage={toggleNewMessage}
                                                    setLoadingUser={setLoadingUser}
                                                    isComingFromContactPage={isComingFromContactPage}
                                                    setIsComingFromContactPage={setIsComingFromContactPage}
                                                    setIsInputClicked={setIsInputClicked}
                                                />
                                            </Main>
                                    }
                                </Drawer>
                            ) : (
                                !isMobile && (
                                    <Drawer
                                        sx={
                                            isMobile
                                                ? {
                                                    flexShrink: 0,
                                                    transition: "transform 0.5s ease-in-out",
                                                    transform: openMessageDrawer
                                                        ? "translateX(0)"
                                                        : `translateX(1000px)`,
                                                    "& .MuiDrawer-paper": {
                                                        boxSizing: "border-box",
                                                        position: "relative",
                                                        border: "none",
                                                    },
                                                    display: openMessageDrawer ? "block" : "none",
                                                }
                                                : {
                                                    width: drawerWidth,
                                                    flexShrink: 0,
                                                    transition: "transform 0.5s ease-in-out",
                                                    transform: openMessageDrawer
                                                        ? "translateX(0)"
                                                        : `translateX(1000px)`,
                                                    "& .MuiDrawer-paper": {
                                                        width: drawerWidth,
                                                        boxSizing: "border-box",
                                                        position: "relative",
                                                        border: "none",
                                                    },
                                                    display: openMessageDrawer ? "block" : "none",
                                                }
                                        }
                                        variant={isMobile ? "permanent" : "permanent"}
                                        open={openMessageDrawer}
                                        anchor="left"
                                    >
                                        <MessagesDashboard
                                            setResetScroll={setResetScroll}
                                            fetchUserMessages={fetchUserMessages}
                                            setSearch={setSearch}
                                            search={search}
                                            open={openMessageDrawer}
                                            connections={connections}
                                            setConnections={setConnections}
                                            active={active}
                                            setActive={setActive}
                                            setToggleNewMessage={setToggleNewMessage}
                                            toggleNewMessage={toggleNewMessage}
                                            setLoadingUser={setLoadingUser}
                                        />
                                    </Drawer>
                                )
                            )}
                        {isShowMessages && isMobile ? (
                            <Main open={openInfoDrawer}>
                                <ShowMessages
                                    loadingUser={loadingUser}
                                    resetScroll={resetScroll}
                                    userMessageLastSeqValue={userMessageLastSeqValue}
                                    newMessageRecivedlastSeq={newMessageRecivedlastSeq}
                                    setUserMessages={setUserMessages}
                                    fetchUserMessages={fetchUserMessages}
                                    userMessages={userMessages}
                                    setOpenMessageDrawer={setOpenMessageDrawer}
                                    openMessageDrawer={openMessageDrawer}
                                    setOpenInfoDrawer={setOpenInfoDrawer}
                                    openInfoDrawer={openInfoDrawer}
                                    connections={connections}
                                    setConnections={setConnections}
                                    setToggleNewMessage={setToggleNewMessage}
                                    toggleNewMessage={toggleNewMessage}
                                    setLoadingUser={setLoadingUser}
                                    setIsComingFromContactPage={setIsComingFromContactPage}
                                    setIsInputClicked={setIsInputClicked}
                                />
                            </Main>
                        ) : (
                            !isMobile && (

                                <Main open={openInfoDrawer}>
                                    <ShowMessages
                                        newMessageRecivedlastSeq={newMessageRecivedlastSeq}
                                        loadingUser={loadingUser}
                                        resetScroll={resetScroll}
                                        userMessageLastSeqValue={userMessageLastSeqValue}
                                        setUserMessages={setUserMessages}
                                        fetchUserMessages={fetchUserMessages}
                                        userMessages={userMessages}
                                        setOpenMessageDrawer={setOpenMessageDrawer}
                                        openMessageDrawer={openMessageDrawer}
                                        setOpenInfoDrawer={setOpenInfoDrawer}
                                        openInfoDrawer={openInfoDrawer}
                                        connections={connections}
                                        setConnections={setConnections}
                                        setToggleNewMessage={setToggleNewMessage}
                                        toggleNewMessage={toggleNewMessage}
                                        setLoadingUser={setLoadingUser}
                                        setIsComingFromContactPage={setIsComingFromContactPage}
                                        setIsInputClicked={setIsInputClicked}
                                    />
                                </Main>
                            )
                        )}
                        {isProfile && isMobile ? (
                            <Drawer
                                sx={
                                    isMobile
                                        ? {
                                            flexShrink: 0,
                                            "& .MuiDrawer-paper": {
                                                position: "relative",
                                                boxSizing: "border-box",
                                                border: "none",
                                            },
                                            display: openInfoDrawer ? "block" : "none",
                                        }
                                        : {
                                            width: drawerWidth2,
                                            flexShrink: 0,
                                            "& .MuiDrawer-paper": {
                                                width: drawerWidth2,
                                                position: "relative",
                                                boxSizing: "border-box",
                                                border: "none",
                                            },
                                            display: openInfoDrawer ? "block" : "none",
                                        }
                                }
                                variant="permanent"
                                open={openInfoDrawer}
                                anchor="right"
                            >
                                <Profile
                                    detail={detail}
                                    open={openInfoDrawer}
                                    openInfoDrawer={openInfoDrawer}
                                    setOpenInfoDrawer={setOpenInfoDrawer}
                                />
                            </Drawer>
                        ) : (
                            !isMobile && (
                                <Drawer
                                    sx={
                                        isMobile
                                            ? {
                                                flexShrink: 0,
                                                "& .MuiDrawer-paper": {
                                                    position: "relative",
                                                    boxSizing: "border-box",
                                                    border: "none",
                                                },
                                                display: openInfoDrawer ? "block" : "none",
                                            }
                                            : {
                                                width: drawerWidth2,
                                                flexShrink: 0,
                                                "& .MuiDrawer-paper": {
                                                    width: drawerWidth2,
                                                    position: "relative",
                                                    boxSizing: "border-box",
                                                    border: "none",
                                                },
                                                display: openInfoDrawer ? "block" : "none",
                                            }
                                    }
                                    variant="permanent"
                                    open={openInfoDrawer}
                                    anchor="right"
                                >
                                    <Profile
                                        open={openInfoDrawer}
                                        openInfoDrawer={openInfoDrawer}
                                        setOpenInfoDrawer={setOpenInfoDrawer}
                                    />
                                </Drawer>
                            )
                        )}
                    </Box>
            )
            }


        </Box>
    );
};

export default Messages;
