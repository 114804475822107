import axios from "axios";
import config from "../config";
import { empty, extractDataFromArray, parseResponseAxios, toastMessage } from "../utils/common";
import errorAndHelperText from "../commonComponents/CommonConstants/errorAndHelperText.json"

axios.defaults.withCredentials = true


const UserService = {
    isLoggedIn: async (successCallback = () => { }, errorCallback = () => { }) => {
        await axios(config.apiUrl + "/services/openapi?cmd=isLoggedIn", {
        }).then(async response => {
            successCallback(response)
        }).catch(() => {
            errorCallback()
        })
    },
    loginUserWithToken: async (token, successCallback = () => { }, errorCallback = () => { }) => {
        await axios({
            method: 'post',
            url: config.loginURL,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: {
                cmd: 'loginUserWithToken',
                tokenV1: token
            },
        }).then(async response => {
            successCallback(response)
        }).catch(() => {
            errorCallback()
        })
    },
    logoutUser: async (successCallback = () => { }, errorCallback = () => { }) => {
        await axios({
            method: 'post',
            url: config.loginURL,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: {
                cmd: 'signOut',
            }
        }).then(async response => {
            successCallback(response)
        }).catch(() => {
            errorCallback()
        })
    },
    loginUser: async (email, passwd, successCallback = () => { }, errorCallback = () => { }) => {
        await axios({
            method: 'post',
            url: config.loginURL,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: {
                cmd: 'loginUser',
                email,
                passwd
            },
            // auth: {
            //     username: 'testuser',
            //     password: 'devuser@123'
            // }
        }).then(async response => {
            // let {setUser} = UserAuth();
            response = parseResponseAxios(response)
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                successCallback(extractDataFromArray(response, ['data', 0], null))
            } else {
                errorCallback(extractDataFromArray(response, ['errorCode'], 0), extractDataFromArray(response, ['errorMsg'], 0))
            }
        }).catch(() => {
            errorCallback()
        })
    },
    registerUser: async (data, successCallback = () => { }, errorCallback = () => { }) => {
        data['cmd'] = 'signupUser';
        data['user_agree'] = 'Y';
        await axios({
            method: 'post',
            url: config.loginURL,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data,
        }).then(async response => {
            response = parseResponseAxios(response)
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                successCallback(extractDataFromArray(response, ['data'], null))
            } else {
                errorCallback(extractDataFromArray(response, ['errorCode'], 0), extractDataFromArray(response, ['errorMsg'], 0))
            }
        }).catch(() => {
            errorCallback()
        })
    },
    loginUserInExp: async (email, fname, lname, profile_pic, source, phone, user_interest, successCallback = () => { }, errorCallback = () => { }) => {
        await axios({
            method: 'post',
            url: config.apiUrl + "/services/api",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: {
                cmd: 'saveProfile',
                email,
                fname,
                lname,
                profile_pic,
                source,
                phone,
                user_interest,
            },
        }).then(async response => {
            // let {setUser} = UserAuth();
            response = parseResponseAxios(response)
            let errorMsg = extractDataFromArray(response, ['errorMsg'], '')
            if (!empty(errorMsg)) {
                // toast.error(errorMsg, {
                //     theme: "colored",
                // })
                toastMessage({
                    message: errorMsg,
                    type: 'error',
                });
                if (!empty(errorCallback)) {
                    errorCallback()
                }
                return
            }
            // console.log(response, extractDataFromArray(response, ['data'], null));
            successCallback(extractDataFromArray(response, ['data'], null))
        }).catch(() => {
            errorCallback()
        })
    },
    sendVerificationCode: async (callback = () => { }, errCallback = () => { }) => {
        await axios.get(config.apiUrl + "/services/api", {
            params: {
                cmd: 'sendVerificationCode',
            }
        }).then((response) => {
            response = parseResponseAxios(response);
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                const data = extractDataFromArray(response, ['data'], []);
                callback(data);

            } else if (extractDataFromArray(response, ['errorCode'], 1) === 1) {
                const errorMsg = extractDataFromArray(response, ['errorMsg'], []);
                // toast.error(errorMsg, {
                //     theme: "colored",
                // })
                toastMessage({
                    message: errorMsg,
                    type: 'error',
                });
                errCallback(errorMsg);
            }
        });
    },
    verifyEmail: async (otp, email, callback, errCallback) => {
        let formData = new FormData();
        formData.append("cmd", 'verifyEmail')
        formData.append("otp", otp)
        formData.append("email", email)
        await axios.post(config.apiUrl + "/services/openapi", formData).then((response) => {
            response = parseResponseAxios(response);

            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                const data = extractDataFromArray(response, ['data'], []);
                callback(data);
            } else if (extractDataFromArray(response, ['errorCode'], 1) === 1) {
                const errorMsg = extractDataFromArray(response, ['errorMsg'], []);
                errCallback(errorMsg);
            } else {
                // toast.error("Something went wrong, Please try again lator", {
                //     theme: "colored",
                // })
                toastMessage(errorAndHelperText.something_went_wrong);

            }
        });
    },
    getAccountCompletionStatus: async (callback) => {
        await axios.get(config.apiUrl + "/services/api", {
            params: {
                cmd: 'getAccountCompletionStatus',
            }
        }).then((response) => {
            response = parseResponseAxios(response);
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                const data = extractDataFromArray(response, ['data'], []);
                callback(data);
            }
        });
    },
    getIDVerificationToken: async (callback) => {
        await axios.get(config.apiUrl + "/services/api", {
            params: {
                cmd: 'getIDVerificationToken',
            }
        }).then((response) => {
            response = parseResponseAxios(response);
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                const data = extractDataFromArray(response, ['data'], []);
                callback(data);
            }
        });
    },
    // updateVerificationStatus: async (callback) => {
    //     let formData = new FormData();
    //     formData.append("cmd", 'updateVerificationStatus')
    //     await axios.post(config.apiUrl + "/services/api", formData).then((response) => {
    //         response = parseResponseAxios(response);
    //         if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
    //             const data = extractDataFromArray(response, ['data'], []);
    //             callback(data);
    //         }
    //     });
    // },
    updateVerificationStatus: async (status, callback) => {
        let formData = new FormData();
        formData.append("cmd", 'updateVerificationStatus')
        formData.append("status", status)
        await axios.post(config.apiUrl + "/services/api", formData).then((response) => {
            response = parseResponseAxios(response);
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                const data = extractDataFromArray(response, ['data'], []);
                callback(data);
            }
        });
    },
    updatePassword: async (password, callback, errCallback) => {
        let formData = new FormData();
        formData.append("cmd", 'updatePassword');
        formData.append("password", password);

        await axios.post(config.loginURL, formData).then((response) => {
            response = parseResponseAxios(response);
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                const data = extractDataFromArray(response, ['data'], []);
                callback(data);
            } else if (extractDataFromArray(response, ['errorCode'], 1) === 1) {
                const errorMsg = extractDataFromArray(response, ['errorMsg'], []);
                errCallback(errorMsg);
            } else {
                // toast.error("Something went wrong, Please try again lator", {
                //     theme: "colored",
                // })
                toastMessage(errorAndHelperText.something_went_wrong);

            }
        });
    },
    loginWithThirdPartyApp: async (token, successCallback = () => { }, errorCallback = () => { }) => {
        await axios({
            method: 'post',
            url: config.loginURL,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: {
                cmd: 'loginWithThirdPartyApp',
                id_token: token,
                app: 'google',
                form_name: 'search_signup',
                device_type: 'exp'
            },
        }).then(async response => {
            // console.log("Success", response);
            successCallback(response)
        }).catch(() => {
            // console.log("Success", response);
            errorCallback()
        })
    },
    getCurrentLocation: async (callback) => {
        const response = await axios.get(config.apiUrl + "/services/api", {
            params: {
                cmd: "getMyLocationByIP"
            }
        });;

        const parsedResponse = parseResponseAxios(response);

        if (extractDataFromArray(parsedResponse, ['errorCode'], 1) === 0) {
            // Call the callback function with the parsed response
            if (callback) {
                callback(extractDataFromArray(parsedResponse, ['data'], {}));
            }
        } else {
            toastMessage({
                message: extractDataFromArray(parsedResponse, ['errorMsg'], 'Something Went Wrong.'),
                type: 'error'
            })
        }

    },
}

export default UserService;