import { Box, Button, ButtonGroup, Divider, Grid, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { PreviewConfig } from './PreviewConfig/previewConfig';
import * as Icon from "@mui/icons-material";
import { useEffect, useMemo, useRef, useState } from 'react';
import { CommonContext } from '../../store/context/commonContext';
import { changeTimeFormat, empty, extractDataFromArray, generatePhrase, toastMessage } from '../../utils/common';
import ExpHeaderGallery from '../../commonComponents/Experience/ExpPackageComponents/ExpHeaderGallary';
import GreyLabel from '../formElement/GreyLabel';
import dayjs from 'dayjs';
import OptionalAddOns from './ExpPackageComponents/OptionalAddOns';
import MeetingAndPickup from './ExpPackageComponents/MeetingAndPickup';
import ThingsToKnow from './ExpPackageComponents/ThingsToKnow';
import RulesAndSafety from './ExpPackageComponents/RulesAndSafety';
import config from '../../config';
import AdminBanner from '../previewPageComponents/AdminBanner';
import { Link, useLocation } from 'react-router-dom';
import WishlistService from '../../services/wishlist';
import { toast } from 'react-toastify';
import { UserAuth } from '../../store/context/userContext';
import ExpBookingCard from './ExpBookingCard';
import ExperienceService from '../../services/experience';
import { FROM_TIME, RESPONSIVE_SPACING, TO_TIME } from '../CommonConstants/commonKeys';
import ReviewRecievedCard from '../revieRecievedCard/index'
import ExpEnquiryPopUp from '../ExpEnquiryPopUp';
import CreateChip from '../CreateChip';
import ShareButton from '../ShareButton';
import { Helmet } from 'react-helmet';



const PackageExperience = ({ completeExperienceData, isPreview, isMobileButtonClicked, isDesktop, showPackageFullSize }) => {
    const [wishlist, setWishlist] = useState(false)
    const location = useLocation()
    const seq = extractDataFromArray(completeExperienceData, ['experience_seq'], '')
    let tempStartingPrice = extractDataFromArray(completeExperienceData, ['starting_price'], 0)
    tempStartingPrice = parseInt(tempStartingPrice)
    const starting_price = tempStartingPrice

    const memoizedTypeIdObj = useMemo(() => generateTypeIdObject(completeExperienceData.experience_data), [completeExperienceData.experience_data]);
    const [typeIdObj, setTypeIdObj] = useState(memoizedTypeIdObj);

    const minValue = 1;
    const [maxValue, setMaxValue] = useState(extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.guests}`, 'max_guests'], 99)) // max value set for count of max guest selection
    const [bookedData, setBookedData] = useState([])

    const [guest, setGuest] = useState(minValue);
    const { isMobile, isNineHundred, OpenExpEnquiryPopup, setOpenExpEnquiryPopup, searchFilters, setSearchFilters, isTablet } = CommonContext()
    const { user } = UserAuth()

    const pricingData = extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.pricing}`, 'slot_list'], [])
    const durationObj = extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.pricing}`, 'package_duration'], [])
    const notAvailableDates = extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.availability}`, 'not_available_dates'], [])
    const [open, setOpen] = useState(false);
    const availabilityRange = [extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.availability}`, 'from_date'], null), extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.availability}`, 'to_date'], null)]
    // console.log(availabilityRange);
    const [images, setImages] = useState(extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.photos}`, 'photo_list'], []))
    const isActive = (extractDataFromArray(completeExperienceData, ['is_active'], "0")) === "1"
    // console.log(isActive, completeExperienceData);

    useEffect(() => {
        {
            config.module === 'guest' && ExperienceService.getExpBookedData(seq, (data) => {
                setBookedData(data)
            })
        }

        setSearchFilters && setSearchFilters({ ...searchFilters, 'activity': 'experience' }); // Added this to set experience as selected drop down option in header search bar on experience details page

        if (images.length === 0) {
            const newImages = [
                {
                    "url": "../landowner/assets/images/default.png",
                    "name": "dummy-image.jpg",
                    "type": "IMAGE",
                    "file_type": "IMAGE"
                },
                {
                    "url": "../landowner/assets/images/default.png",
                    "name": "dummy-image.jpg",
                    "type": "IMAGE",
                    "file_type": "IMAGE"
                },
                {
                    "url": "../landowner/assets/images/default.png",
                    "name": "dummy-image.jpg",
                    "type": "IMAGE",
                    "file_type": "IMAGE"
                },
                {
                    "url": "../landowner/assets/images/default.png",
                    "name": "dummy-image.jpg",
                    "type": "IMAGE",
                    "file_type": "IMAGE"
                },
            ]
            setImages(newImages)
        }
    }, [])

    function generateTypeIdObject(data) {

        const result = {};

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const item = data[key];
                const type = item.type;
                const id = item.id;

                if (type && id) {
                    result[type] = id;
                }
            }
        }
        return result
        // setTypeIdObj(result);
    }

    const toggleWishlist = () => {
        setWishlist(!wishlist)
        if (!wishlist) {
            setWishlist(true)
            WishlistService.saveWishlist('E', seq, (data, errorMsg) => {
                if (data) {
                    toastMessage({
                        message: errorMsg,
                        type: 'success',
                    })
                }
                setWishlist(true)
            })
        } else {
            setWishlist(false)
            WishlistService.removeFromWishlist('E', seq, (data, errorMsg) => {
                toastMessage({
                    message: errorMsg,
                    type: 'success',
                })
                setWishlist(false)
            })
        }
    }

    // This use effect is to refresh wishlist object on user login or logout status change
    useEffect(() => {
        if (!user) {
            setWishlist(false)
        }
    }, [user])

    useEffect(() => {
        if (config.module === 'guest' && user) {
            // console.log('isWishlistcalled');
            WishlistService.isWishlist(seq, 'E', (data) => {
                // console.log(data,'iswishlist data')
                setWishlist(data)
            })
        }
    }, [seq, user])

    const handleChange = (event) => {
        setGuest(event.target.value);
    };

    const guestOptions = Array.from({ length: maxValue - minValue + 1 }, (_, i) => i + minValue);

    const scrollToReviews = () => {
        const reviewsElement = document.querySelector('.reviews');
        if (reviewsElement) {
            reviewsElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleClose = () => {
        setOpenExpEnquiryPopup(false);
    };

    const scrollContainerRef = useRef(null);

    // Add keyboard event listener for arrow key scrolling
    useEffect(() => {
        if (!isPreview) return;

        const handleKeyDown = (e) => {
            if (!scrollContainerRef.current) return;

            const container = scrollContainerRef.current;
            const regularScrollAmount = 100; // For arrow keys
            const pageScrollAmount = container.clientHeight - 40; // For page up/down, leaving some overlap

            switch (e.key) {
                case 'ArrowDown':
                    container.scrollBy({
                        top: regularScrollAmount,
                        behavior: 'smooth'
                    });
                    break;
                case 'ArrowUp':
                    container.scrollBy({
                        top: -regularScrollAmount,
                        behavior: 'smooth'
                    });
                    break;
                case 'PageDown':
                    container.scrollBy({
                        top: pageScrollAmount,
                        behavior: 'smooth'
                    });
                    break;
                case 'PageUp':
                    container.scrollBy({
                        top: -pageScrollAmount,
                        behavior: 'smooth'
                    });
                    break;
                case 'Home':
                    if (e.ctrlKey) {
                        container.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        });
                    }
                    break;
                case 'End':
                    if (e.ctrlKey) {
                        container.scrollTo({
                            top: container.scrollHeight,
                            behavior: 'smooth'
                        });
                    }
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isPreview]);

    // Helper functions for meta data
    const generateMetaDescription = (experienceData, typeIdObj) => {
        const title = extractDataFromArray(experienceData, ['experience_data', `${typeIdObj.general_info}`, 'title'], '');
        const city = extractDataFromArray(experienceData, ['experience_data', `${typeIdObj.location}`, 'city'], '');
        const expType = extractDataFromArray(experienceData, ['experience_data', `${typeIdObj.general_info}`, 'experience_type'], '');
        const duration = generatePhrase(
            extractDataFromArray(experienceData, ['experience_data', `${typeIdObj.pricing}`, 'package_duration', 'value'], 0),
            extractDataFromArray(experienceData, ['experience_data', `${typeIdObj.pricing}`, 'package_duration', 'unit'], 'days') === 'days' ? 'Day' : 'Hour'
        );
        const price = extractDataFromArray(experienceData, ['starting_price'], '');

        return `Experience ${title} in ${city}. This ${expType} adventure lasts ${duration} and starts from $${price} per person. Book your outdoor experience today!`;
    };

    const getPrimaryImageUrl = (images) => {
        if (!images || images.length === 0) {
            return '../landowner/assets/images/default.png';
        }
        return `https:${images[0].url}`;
    };

    const generateKeywords = (experienceData, typeIdObj) => {
        const title = extractDataFromArray(experienceData, ['experience_data', `${typeIdObj.general_info}`, 'title'], '');
        const city = extractDataFromArray(experienceData, ['experience_data', `${typeIdObj.location}`, 'city'], '');
        const expType = extractDataFromArray(experienceData, ['experience_data', `${typeIdObj.general_info}`, 'experience_type'], '');
        const difficulty = extractDataFromArray(experienceData, ['experience_data', `${typeIdObj.general_info}`, 'difficulty_level'], '');

        return `${title}, ${city}, ${expType}, ${difficulty}, outdoor activities, adventure experience, guided tours, outdoor adventures`;
    };

    return (
        <>
            <Helmet>
                <title>{`${extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.general_info}`, 'title'], '')} | FarWide`}</title>
                <meta name="description" content={generateMetaDescription(completeExperienceData, typeIdObj)} />

                {/* Open Graph tags for social sharing */}
                <meta property="og:title" content={`${extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.general_info}`, 'title'], '')} | FarWide`} />
                <meta property="og:description" content={generateMetaDescription(completeExperienceData, typeIdObj)} />
                <meta property="og:image" content={getPrimaryImageUrl(images)} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location.href} />

                {/* Twitter Card tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`${extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.general_info}`, 'title'], '')} | FarWide`} />
                <meta name="twitter:description" content={generateMetaDescription(completeExperienceData, typeIdObj)} />
                <meta name="twitter:image" content={getPrimaryImageUrl(images)} />

                {/* Additional meta tags */}
                <meta name="keywords" content={generateKeywords(completeExperienceData, typeIdObj)} />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={window.location.href} />

                {/* Additional metadata for rich snippets */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "TouristAttraction",
                        "name": extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.general_info}`, 'title'], ''),
                        "description": generateMetaDescription(completeExperienceData, typeIdObj),
                        "image": getPrimaryImageUrl(images),
                        "address": {
                            "@type": "PostalAddress",
                            "addressLocality": extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.location}`, 'city'], ''),
                            "addressCountry": "US"
                        },
                        "priceRange": `$${starting_price} per person`,
                        "aggregateRating": {
                            "@type": "AggregateRating",
                            "ratingValue": parseFloat(extractDataFromArray(completeExperienceData, ['average_rating'], '0')).toFixed(1),
                            "reviewCount": parseInt(extractDataFromArray(completeExperienceData, ['review_count'], '0'))
                        }
                    })}
                </script>
            </Helmet>

            <div>
                {OpenExpEnquiryPopup && (
                    <ExpEnquiryPopUp
                        open={OpenExpEnquiryPopup}
                        setOpenExpEnquiryPopup={setOpenExpEnquiryPopup}
                        experienceData={completeExperienceData}
                        handleClose={handleClose}
                    />
                )}
            </div>

            <Grid container maxWidth="xl" sx={{ m: "auto", paddingInline: showPackageFullSize || window.location.href.includes('/landowner') && location.pathname.includes('/experience') ? '0px' : '16px', marginTop: isPreview ? "31px" : 'unset' }}>
                <Box sx={{
                    overflow: (config.module === 'lister' || isNineHundred) ? 'hidden' : 'unset',
                    borderRadius: isMobileButtonClicked ? '48px' : '16px',
                    margin: 'auto',
                }}>
                    <Box
                        maxWidth={isMobileButtonClicked ? '390px' : (showPackageFullSize ? '100%' : (isDesktop ? '1300px' : '390px'))}
                        ref={scrollContainerRef}
                        // conditional CSS for Preview popup and final preview page
                        sx={isPreview ? {
                            backgroundColor: '#fff',
                            height: showPackageFullSize ? '100vh' : 'calc(100vh - 160px)',
                            width: '100%',
                            borderRadius: isMobileButtonClicked ? '48px' : '16px',
                            m: 'auto',
                            paddingInline: isMobileButtonClicked ? '32px' : '50px',
                            overflow: isPreview ? 'auto' : 'unset',
                            pt: 4,
                            border: '1px solid #E5E7EB',
                            boxShadow: '0df 0 16px 0 rgba(0, 0, 0, 0.05)'

                        }
                            : {
                                backgroundColor: '#fff',
                                width: '100%',
                                borderRadius: '16px',
                                m: 'auto',
                                overflow: isPreview ? 'auto' : 'unset',
                            }
                        }
                    >
                        {
                            <Box>
                                {
                                    config.module === 'admin' &&
                                    <Grid item md={12} xs={12} sx={{ mb: 3 }}>
                                        <AdminBanner
                                            lister_name={extractDataFromArray(completeExperienceData, ['lister', 'fname'], 'Test') + " " + extractDataFromArray(completeExperienceData, ['lister', 'lname'], 'Test')}
                                            lister_pic={extractDataFromArray(completeExperienceData, ['lister', 'profile_pic'], '')}
                                            lister_seq={extractDataFromArray(completeExperienceData, ['lister_seq'], '')}
                                            listing_seq={extractDataFromArray(completeExperienceData, ['experience_seq'], '')}
                                            status={extractDataFromArray(completeExperienceData, ['verification_status'], '')}
                                            listing_type={'experience'}
                                        />
                                    </Grid>
                                }
                                <Box sx={{ display: 'flex', flexDirection: !isNineHundred ? isMobileButtonClicked ? 'column-reverse' : 'column' : 'column-reverse' }}>
                                    <Box sx={{ mb: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                            <Typography variant={'h4'} sx={{
                                                width: 'fit-content',
                                                fontWeight: isNineHundred && '600',
                                                fontSize: isMobileButtonClicked && '1.5rem'
                                            }}>
                                                {extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.general_info}`, 'title'], '')}
                                            </Typography>
                                            {/* This part is only for preview purpose in lister page. */}
                                            {isPreview && !isMobileButtonClicked && <ButtonGroup sx={{ float: 'right', position: 'relative', display: 'flex', gap: 1 }}>
                                                <IconButton sx={{ height: 40, weight: 40, borderRadius: '8px', backgroundColor: '#F5F5F6' }}>
                                                    <Icon.Favorite sx={{ color: "#e94a4a" }} />
                                                </IconButton>
                                                <IconButton sx={{ height: 40, weight: 40, borderRadius: '8px', backgroundColor: '#F5F5F6' }}><Icon.Share /></IconButton>
                                            </ButtonGroup>}
                                            {/* this is actual wishlist and share button  */}
                                            {config.module === 'guest' && !isNineHundred && <ButtonGroup sx={{ float: 'right', position: 'relative', display: 'flex', gap: 1 }}>
                                                <IconButton onClick={toggleWishlist} sx={{ height: 40, weight: 40, borderRadius: '8px', backgroundColor: '#F5F5F6' }}>{wishlist ? <Icon.Favorite sx={{ color: "#e94a4a" }} /> : <Icon.FavoriteBorderOutlined />}</IconButton> : <></>
                                                <ShareButton shareUrl={window.location.href} />
                                            </ButtonGroup>}
                                            {
                                                config.module === 'lister' && !isPreview &&
                                                <Box sx={{ minWidth: !isNineHundred ? '150px' : 'unset' }}>
                                                    {!isNineHundred && <Box sx={{ float: 'right', mr: isMobile ? 0 : 1, mt: isMobile ? 2 : 0 }}>
                                                        <ShareButton shareUrl={window.location.href.includes('/landowner') ? window.location.href.replace('/landowner', '') : window.location.href} />
                                                    </Box>}
                                                    <Link to={'/createExperience' + "?experience_seq=" + extractDataFromArray(completeExperienceData, ['experience_seq'], '') + '&edit=1'}>
                                                        <Button size="small" variant="contained" sx={{ float: 'right', mr: isMobile ? 0 : 1, mt: isMobile ? 2 : 0, height: '42px' }}>
                                                            <Icon.EditOutlined sx={{ mr: 1 }} />Edit</Button>
                                                    </Link>
                                                </Box>
                                            }
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: 2,
                                                flexWrap: 'wrap'
                                            }}
                                        >   {
                                                !empty(extractDataFromArray(completeExperienceData, ['review_count'])) &&
                                                <Box sx={{ display: "flex", gap: 1 }}>
                                                    <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                                                        <Icon.Star sx={{ color: "#F7C32E" }} />
                                                        <Typography variant="body1" fontWeight={'bold'}  >
                                                            {parseFloat(extractDataFromArray(completeExperienceData, ['average_rating'])).toFixed(1)}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        onClick={scrollToReviews}
                                                        sx={{ display: 'flex', gap: 2, alignItems: 'center', textDecoration: 'underline', cursor: 'pointer' }}
                                                    >
                                                        <Typography variant="body1" color='grey300'>
                                                            {generatePhrase(Number(extractDataFromArray(completeExperienceData, ['review_count'], 0)), 'review')}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            }
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Icon.LocationOnOutlined sx={{ mr: 1, color: 'var(--grey900)' }} />
                                                <Typography sx={{ color: 'var(--grey900)' }}>
                                                    Nearest Town, {`${extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.location}`, 'city'], [])}`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ mb: 2.5 }}>
                                        <ExpHeaderGallery type={'EXP'} seq={seq} isPreview={true} images={images} setImages={setImages} isMobileButtonClicked={isMobileButtonClicked} />
                                    </Box>
                                </Box>


                                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, pt: isNineHundred ? 0 : isMobileButtonClicked ? 0 : 3, pb: isNineHundred ? 0 : isMobileButtonClicked && 13.5, }}>

                                    <Box
                                        sx={{
                                            width: !isNineHundred ? isMobileButtonClicked ? '100%' : '55%' : '100%',
                                            display: 'flex', flexDirection: 'column',
                                        }}>
                                        <Box sx={{ display: 'flex', gap: 1, "& .MuiChip-label": { color: 'var(--grey900)' }, mb: isNineHundred ? 3 : isMobileButtonClicked ? 3 : 5, flexWrap: 'wrap' }}>
                                            {/* <Chip label={extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.general_info}`, 'experience_type'], [])} sx={{ height: '28px' }} /> */}

                                            <CreateChip caption={extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.general_info}`, 'experience_type'], [])} sx={{ height: '28px' }} />

                                            {
                                                extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.general_info}`, 'guest_skills'], []) === "Yes" &&
                                                <>
                                                    {/* <Chip label={extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.general_info}`, 'skill_level'], [])} sx={{ height: '28px' }} /> */}

                                                    <CreateChip caption={extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.general_info}`, 'skill_level'], [])} sx={{ height: '28px' }} />

                                                </>
                                            }

                                            {/* <Chip label={extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.general_info}`, 'difficulty_level'], [])} sx={{ height: '28px' }} /> */}

                                            <CreateChip caption={extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.general_info}`, 'difficulty_level'], [])} sx={{ height: '28px' }} />

                                            {/* <Chip label={generatePhrase(extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.pricing}`, 'package_duration', 'value'], 0), extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.pricing}`, 'package_duration', 'unit'], 'days') === 'days' ? 'Day' : 'Hour')} sx={{ height: '28px' }} /> */}

                                            <CreateChip caption={generatePhrase(extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.pricing}`, 'package_duration', 'value'], 0), extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.pricing}`, 'package_duration', 'unit'], 'days') === 'days' ? 'Day' : 'Hour')} sx={{ height: '28px' }} />
                                        </Box>
                                        {completeExperienceData.experience_structure.map(sectionGroup => (
                                            <>
                                                {
                                                    sectionGroup.caption === "Details" &&
                                                    <>
                                                        {sectionGroup.section_data.map((section, sectionIndex) => {
                                                            return section.type === "draggable" ? (
                                                                <>
                                                                    {section.components.map((componentId, componentIndex) => {
                                                                        const sectionData = completeExperienceData?.experience_data[componentId];
                                                                        return (
                                                                            <Box className={componentId}>
                                                                                {sectionData && PreviewConfig[sectionData?.type]?.renderPreviewComponent([sectionData], isMobileButtonClicked)}
                                                                            </Box>
                                                                        );
                                                                    })}
                                                                </>
                                                            )
                                                                : null
                                                        })}
                                                    </>
                                                }
                                            </>
                                        ))}
                                        <Box>
                                            {extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.addon}`, 'addon_list'], []).length > 0 && extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.addon}`, 'display'], 0) != 0 &&

                                                <>
                                                    <OptionalAddOns formData={extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.addon}`], [])} isMobileButtonClicked={isMobileButtonClicked} />
                                                    <Divider sx={{ my: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING }} />
                                                </>}
                                            {config.module === 'guest' && <Grid item xs={12} sm={12} md={12} sx={{ mb: 5 }}>
                                                <Box className='reviews' >
                                                    <ReviewRecievedCard listingData={completeExperienceData} type={'E'} listingSeq={seq} />
                                                </Box>
                                            </Grid>}
                                            {
                                                !(extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.meeting_and_pickup}`, 'display'], 0) == 0
                                                    && extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.things_to_know}`, 'display'], 0) == 0
                                                    && extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.safety_rule}`, 'display'], 0) == 0) &&
                                                <Typography variant='h5' sx={{ fontWeight: '600', mb: 2 }}>Additional Details</Typography>
                                            }
                                            {extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.meeting_and_pickup}`, 'display'], 0) != 0 && <>
                                                <Box sx={{ mb: isNineHundred || isMobileButtonClicked ? 3 : 6 }}>
                                                    <MeetingAndPickup
                                                        formData={extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.meeting_and_pickup}`], [])}
                                                        isMobileButtonClicked={isMobileButtonClicked}
                                                    />
                                                </Box>
                                            </>}
                                            {extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.things_to_know}`, 'display'], 0) != 0 && <>
                                                <ThingsToKnow
                                                    formData={extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.things_to_know}`], [])}
                                                    isMobileButtonClicked={isMobileButtonClicked}
                                                />
                                            </>}
                                            {extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.safety_rule}`, 'display'], 0) != 0 && <RulesAndSafety
                                                formData={extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.safety_rule}`], [])} isMobileButtonClicked={isMobileButtonClicked}
                                            />}
                                            {/* mobile view UI, in lister preview for experience details  */}
                                            {isMobileButtonClicked && <Box sx={{
                                                // height: '102px',
                                                width: '390px',
                                                borderTop: '2px solid #E9EBEE',
                                                zIndex: 9999999,
                                                position: 'fixed',
                                                bottom: isTablet ? 0 : 28,
                                                backgroundColor: 'white',
                                                px: isTablet ? 2 : 3,
                                                pt: 3,
                                                pb: '30px',
                                                ml: '-33px',
                                                borderRadius: '0px 0px 48px 48px',
                                                display: open ? 'none' : 'flex',
                                                justifyContent: 'space-between',
                                                borderBottom: '1px solid #E5E7EB',
                                                "& .MuiButtonBase-root": { p: '6px 8px' },
                                            }}>
                                                <Box sx={{ display: 'flex', "& .MuiTypography-root": { textWrap: 'nowrap' }, flexDirection: 'column' }}>
                                                    {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                        <Typography variant='subtitle1' sx={{ fontWeight: '600' }}>
                                                            From ${completeExperienceData.starting_price}
                                                        </Typography>
                                                        <Typography sx={{ color: 'var(--textGrey)' }}> / person</Typography>
                                                    </Box> */}
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Typography variant='body2'>
                                                            From&nbsp;
                                                        </Typography>
                                                        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                                                            ${completeExperienceData.starting_price}&nbsp;
                                                        </Typography>
                                                        <Typography variant='body2'>
                                                            per guest
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant='caption' sx={{ fontWeight: '600', textDecoration: 'underline', fontSize: '15px' }} onClick={() => { }}>
                                                        Show all prices
                                                    </Typography>

                                                </Box>
                                                <Button variant='contained' onClick={() => { }}>
                                                    Show Dates
                                                </Button>
                                            </Box>}
                                        </Box>
                                    </Box>
                                    {/* in lister preview, for experience package, this part will render booking card with no actions for preview page */}
                                    {isPreview && !isNineHundred && !isMobileButtonClicked && <Box sx={{
                                        width: '40%',
                                        border: '1px solid #E5E7EB',
                                        borderRadius: '16px',
                                        p: 3,
                                        height: 'fit-content',
                                        position: 'sticky',
                                        top: '-8px',
                                        right: '20px',
                                    }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                mb: 2.5,
                                                flexWrap: 'wrap',
                                                justifyContent: 'flex-end'
                                            }}
                                        >
                                            <Typography variant='body2'>
                                                From&nbsp;
                                            </Typography>
                                            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                                                ${starting_price}&nbsp;
                                            </Typography>
                                            <Typography variant='body2' sx={{ textWrap: 'nowrap' }}>
                                                per guest
                                            </Typography>
                                        </Box>
                                        <Box sx={{ width: '100%', mb: 3, backgroundColor: '#F5F5F6', borderRadius: 2, p: 3, display: 'flex', gap: 3, justifyContent: 'space-between' }}>
                                            <Box sx={{ width: '100%' }}>
                                                <GreyLabel label={'Dates'} />
                                                <Box sx={{
                                                    border: '1px solid #E5E7EB',
                                                    borderRadius: '6px',
                                                    backgroundColor: 'white',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    cursor: 'pointer',
                                                    justifyContent: 'space-between',
                                                    p: 1
                                                }}>
                                                    <Typography sx={{ textOverflow: 'ellipsis', textWrap: 'nowrap' }}>
                                                        {dayjs().format('DD MMM')} - {dayjs().add(2, 'day').format('DD MMM')}
                                                    </Typography>
                                                    <Icon.CalendarTodayOutlined />
                                                </Box>
                                            </Box>
                                            <Box sx={{
                                                "& .MuiInputBase-root": {
                                                    height: '42px',
                                                    backgroundColor: 'white',
                                                }
                                            }}>
                                                <GreyLabel label={'Guests'} />
                                                <Select
                                                    sx={isPreview ? { pointerEvents: 'none' } : {}}
                                                    value={guest}
                                                    onChange={handleChange}
                                                >
                                                    {guestOptions.map(value => (
                                                        <MenuItem key={value} value={value}>{value}</MenuItem>
                                                    ))}
                                                </Select>
                                            </Box>
                                        </Box>
                                        {isPreview &&
                                            <Box>
                                                {pricingData.slice(0, 2).map((item, index) => (
                                                    <Box key={index} sx={{ width: '100%' }}>
                                                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', gap: 1 }}>
                                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                                <Typography variant='subtitle1' fontWeight={600} sx={{ mb: 1 }}>
                                                                    {dayjs().format("ddd, DD MMM")}
                                                                </Typography>
                                                                <Typography variant='caption' sx={{ mb: 1 }}>
                                                                    {changeTimeFormat(item[FROM_TIME])} - {changeTimeFormat(item[TO_TIME])}
                                                                </Typography>
                                                                <Typography variant='caption'>
                                                                    {item.description}
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', width: '100px' }}>
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        alignItems: 'center',
                                                                        flexWrap: 'wrap',
                                                                        justifyContent: 'flex-end',
                                                                        width: '180px'
                                                                    }}
                                                                >
                                                                    <Typography variant='caption'>
                                                                        From&nbsp;
                                                                    </Typography>
                                                                    <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                                                                        ${item.price}&nbsp;
                                                                    </Typography>
                                                                    <Typography variant='caption' sx={{ textWrap: 'nowrap' }}>
                                                                        per guest
                                                                    </Typography>
                                                                </Box>
                                                                <Button variant='contained' sx={{ mt: '8px', width: '100px' }}>Choose</Button>
                                                            </Box>
                                                        </Box>
                                                        <Divider sx={{ my: RESPONSIVE_SPACING }} />
                                                    </Box>
                                                ))}
                                                <Button variant='light' fullWidth>
                                                    Show all dates
                                                </Button>
                                            </Box>
                                        }
                                    </Box>}
                                    {/* in case, there is an active booking and in guest, and desktop view this will rendered by this part  */}
                                    {!isPreview && config.module === 'guest' && isActive && <ExpBookingCard
                                        pricingData={pricingData}
                                        starting_price={starting_price}
                                        guest={guest}
                                        handleChange={handleChange}
                                        guestOptions={guestOptions}
                                        bookedData={bookedData}
                                        maxGuest={maxValue}
                                        notAvailableDates={notAvailableDates}
                                        seq={seq}
                                        durationObj={durationObj}
                                        open={open}
                                        setOpen={setOpen}
                                        availabilityRange={availabilityRange}
                                        bookedSlotsData={bookedData}
                                        title={extractDataFromArray(completeExperienceData, ['experience_data', `${typeIdObj.general_info}`, 'title'], '')}
                                    />}
                                    {/* in case if package is not available or disabled by admin or maybe disabled by lister itself */}
                                    {!isPreview && config.module === 'guest' && !isActive && !isNineHundred && <Box sx={{
                                        width: '40%',
                                        border: '1px solid #E5E7EB',
                                        borderRadius: '16px',
                                        p: 3,
                                        height: 'fit-content',
                                        position: 'sticky',
                                        top: '-8px',
                                        right: '20px',
                                    }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                mb: 2,
                                                justifyContent: 'center',
                                                color: '#e94a4a',
                                            }}
                                        >
                                            Currently unavailable
                                        </Box>
                                        <Button
                                            fullWidth
                                            variant='contained'
                                            sx={{
                                                backgroundColor: 'var(--black)',
                                                ":hover": {
                                                    backgroundColor: 'var(--black)'
                                                }
                                            }}
                                            onClick={() => { setOpenExpEnquiryPopup(true) }}
                                        >
                                            Request Availability
                                        </Button>
                                    </Box>}
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
            </Grid >
            {/* this part will render mobile version of booking cards */}
            {isNineHundred && config.module === 'guest' && isActive && <Box sx={{
                // height: '102px',
                width: '100%',
                borderTop: '2px solid #E9EBEE',
                zIndex: 9999999,
                position: 'fixed',
                bottom: 0,
                backgroundColor: 'white',
                p: 2,
                pb: '30px',
                display: open ? 'none' : 'flex',
                width: '100%',
                justifyContent: 'space-between',
                "& .MuiButtonBase-root": { p: '6px 8px' },
            }}>
                <Box sx={{ display: 'flex', "& .MuiTypography-root": { textWrap: 'nowrap' }, flexDirection: 'column' }}>
                    {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <Typography variant='subtitle1' sx={{ fontWeight: '600' }}>
                            From ${completeExperienceData.starting_price}
                        </Typography>
                        <Typography sx={{ color: 'var(--textGrey)' }}> / person</Typography>
                    </Box> */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Typography variant='body2'>
                            From&nbsp;
                        </Typography>
                        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                            ${completeExperienceData.starting_price}&nbsp;
                        </Typography>
                        <Typography variant='body2' sx={{ textWrap: 'nowrap' }}>
                            per guest
                        </Typography>
                    </Box>
                    <Typography variant='caption' sx={{ fontWeight: '600', textDecoration: 'underline', fontSize: '15px' }} onClick={() => { setOpen(true) }}>
                        Show all prices
                    </Typography>

                </Box>
                <Button variant='contained' size='small' onClick={() => { setOpen(true) }}>
                    Show Dates
                </Button>
            </Box>}
            {/* this part will render mobile version of notAvailable card */}
            {isNineHundred && config.module === 'guest' && !isActive && <Box sx={{
                // height: '102px',
                width: '100%',
                borderTop: '2px solid #E9EBEE',
                zIndex: 9999999,
                position: 'fixed',
                bottom: 0,
                backgroundColor: 'white',
                px: isTablet ? 2 : 3,
                pt: 1,
                pb: 2,
                display: open ? 'none' : 'flex',
                width: '100%',
                justifyContent: 'space-between',
                "& .MuiButtonBase-root": { p: '6px 8px' },
                alignItems: 'center',
                flexDirection: 'column', gap: 1
            }}>
                <Box sx={{ display: 'flex', "& .MuiTypography-root": { textWrap: 'nowrap' }, }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{ color: '#e94a4a', }}>
                            Currently unavailable
                        </Box>
                    </Box>
                </Box>
                <Button fullWidth size='small' variant='contained' sx={{
                    backgroundColor: 'var(--black)',
                    ":hover": {
                        backgroundColor: 'var(--black)'
                    }
                }} onClick={() => { setOpenExpEnquiryPopup(true) }}>
                    Request Availability
                </Button>
            </Box>}
        </>
    )
}
export default PackageExperience