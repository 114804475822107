import * as Icon from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import "../style.css";
import config from "../../../config";
import { CommonContext } from "../../../store/context/commonContext";


const DownloadSection = () => {
    const { isNineHundred } = CommonContext()

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: isNineHundred ? 'column' : 'row',
            gap: 1,
            height: isNineHundred ? 'auto' : '465px',
            mb: { xs: 6, md: 12 }
        }}>
            <Box sx={{ width: isNineHundred ? '100%' : '50%', display: 'flex', gap: 1 }}>
                <Box sx={{ width: '50%', height: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Box sx={{ width: '100%', height: '100%', borderRadius: 2, minHeight: '225px', backgroundImage: 'url(../assets/images/homepage/image_1.png)', backgroundPosition: 'bottom center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>

                    </Box>
                    <Box sx={{ width: '100%', height: '100%', borderRadius: 2, minHeight: '225px', backgroundImage: 'url(../assets/images/homepage/image_2.png)', backgroundPosition: 'bottom center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>

                    </Box>
                </Box>
                <Box sx={{ width: '50%', height: '100%', borderRadius: 2, backgroundImage: 'url(../assets/images/homepage/image_3.png)', backgroundPosition: 'bottom center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>

                </Box>
            </Box>
            <Box sx={{ width: isNineHundred ? '100%' : '50%', }} >
                <Box sx={{ backgroundColor: 'var(--grey300)', backgroundImage: 'url(../assets/images/homepage/bg_wave.png)', height: '100%', backgroundPosition: 'bottom center', backgroundRepeat: 'no-repeat', borderRadius: 4, p: { xs: 4, md: 6, lg: 8 } }}>
                    <Typography variant="h4" sx={{ mb: 4 }}>
                        Earn additional income from your land for recreational access.
                    </Typography>
                    <Box className="download_list" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }} >
                        <Icon.Verified />
                        <Typography sx={{ color: '#747579' }}>Host verified guest on your property. </Typography>
                    </Box>
                    <Box className="download_list" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }} >
                        <Icon.Verified />
                        <Typography sx={{ color: '#747579' }}>List for free and earn up to $25,000 per year.</Typography>
                    </Box>
                    <Box className="download_list" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 4 }} >
                        <Icon.Verified />
                        <Typography sx={{ color: '#747579' }}>Stay 100% in control of your property.</Typography>
                    </Box>
                    <Button variant="contained" href={config.landownerPdf} target="_blank">
                        Download Landowner Guide
                    </Button>

                </Box>
            </Box>
        </Box>
    )
}


export default DownloadSection;
