import { Box, Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { CommonContext } from "../../../store/context/commonContext";
import config from "../../../config";


const TableNoData = ({ header, title, buttonText, buttonLink, image, buttonClick = () => { } }) => {
    const { isTablet } = CommonContext()


    return (
        <Box sx={{ background: 'var(--grey300)', p: isTablet ? 2 : 5, borderRadius: theme => `${theme.card.borderRadius} ` }}>
            <Typography sx={{ mb: 1 }}>{header}</Typography>
            <Typography variant="h4" sx={{ fontSize: { xs: '1rem', sm: '1rem', md: '1.5rem', lg: '1.5rem' } }}>{title}</Typography>
            {
                buttonText && config.module !== 'admin' && <Box sx={{ mt: 5 }} >
                    {buttonLink && buttonLink !== '' ? <Link to={buttonLink}>
                        <Button onClick={buttonClick} size={isTablet ? 'small' : 'medium'} variant="contained">{buttonText}</Button>
                    </Link>
                        :
                        <Button onClick={buttonClick} size={isTablet ? 'small' : 'medium'} variant="contained">{buttonText}</Button>
                    }
                </Box>
            }
            <Grid container>
                <Grid item md={6} >

                </Grid>
                <Grid item md={6} sm={12} xs={12} sx={{ mt: { xs: 5, md: 0 } }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                        <></>
                        <Box>
                            <img width="250px" src={image} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}


export default TableNoData;