import { useCallback, useEffect, useState } from "react";
import { Box, Button, Chip, FormControl, InputAdornment, InputLabel, MenuItem, Select, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, } from "@mui/material";
import { Link } from "react-router-dom";
import TransactionService from "../../../services/transaction";
import * as Icon from "@mui/icons-material";
import TableNoData from "../../../commonComponents/placeholders/TableNoData";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import Pagination from "../../../commonComponents/tableListingComponents/Pagination";
import { capitalizeFirstLetter, debounce, empty, extractDataFromArray } from "../../../utils/common";
import moment from "moment";
import { CommonContext } from "../../../store/context/commonContext";
import config from "../../../config";
import { ACCESS, EXPERIENCE } from "../../../commonComponents/CommonConstants/commonKeys";
import CreateChip from "../../CreateChip";
import { getDashboardData } from "../../CommonConstants/commonReactFunction";


const Transactiontable = ({ setOpen = () => { } }) => {
	const [transaction, setTransaction] = useState([]);
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [searchQuery, setSearchQuery] = useState("");
	const [filterConfig, setFilterConfig] = useState({ search: "", sort: "R", });
	const [totalRecords, setTotalRecords] = useState(0);
	const [loading, setLoading] = useState(true)
	const [toggle, setToggle] = useState(false)
	const { isMobile } = CommonContext();
	const [type, setType] = useState('all');
	const [tableNoDataValues, setTableNoDataValues] = useState(null)
	// const [isRecordExist, setIsRecordExist] = useState(true)

	const fetchTransactions = useCallback(() => {
		TransactionService.getAllTransactions(
			searchQuery,
			filterConfig.sort,
			(page - 1) * rowsPerPage,
			rowsPerPage,
			type,
			(data) => {
				setTransaction(extractDataFromArray(data, ['records'], []));
				setTotalRecords(extractDataFromArray(data, ['count'], []));
				// setIsRecordExist(extractDataFromArray(data, ['is_record_exist'], 1) === 1);
				if (config.module === 'lister') {
					if (empty(extractDataFromArray(data, ['records'], []))) {
						getDashboardData(setTableNoDataValues);
					}
				}
				setLoading(false);
			}
		);
	}, [searchQuery, filterConfig.sort, page, rowsPerPage, type]);

	useEffect(() => {
		fetchTransactions();
	}, [fetchTransactions]);



	useEffect(() => {
		// Reset parameters when the filter changes
		setPage(1); // Reset page to 1
		setSearchQuery(""); // Reset searchQuery to an empty string
		setFilterConfig((prevFilterConfig) => ({ ...prevFilterConfig, sort: "R", search: '' })); // Reset sort to "R"
	}, [type]);

	// Debounced search handler
	const debouncedSearch = useCallback(
		debounce((value) => {
			setPage(1); // Added this condition to fix issue of no results showing when used search
			setSearchQuery(value);
			setToggle(true);
		}, 300),
		[]
	);


	const handleSearch = (event) => {

		// if(event.target.value === '') {
		// 	setToggle(false)
		// } else{
		setToggle(true)
		const searchText = event.target.value;
		// setSearchQuery(searchText); // Update the searchQuery state with the input value
		// // }
		debouncedSearch(searchText);
	};
	const columns = [

		{
			field: "booking_id",
			headerName: "Booking ID",
			flex: 1.5,
			minWidth: 200,
			headerAlign: 'left',
			renderHeader: () => (
			  <Typography sx={{ ml: 2 }}>Booking ID</Typography>
			),
			renderCell: (params) => (
			  <Box sx={{ 
				display: "flex", 
				alignItems: "center", 
				ml: 2,
				pr: 4,
				maxWidth: '100%'
			  }}>
				<Typography variant="body2" sx={{
				  whiteSpace: 'normal',
				  wordWrap: 'break-word',
				  overflow: 'hidden',
				  textOverflow: 'ellipsis',
				  maxWidth: '100%'
				}}>
				  {params.value}
				</Typography>
			  </Box>
			),
		  },

		  {
			field: "transaction_date",
			headerName: "Date",
			flex: 0.5,
			minWidth: 100,
			renderCell: (params) => {
			  const parsedDate = moment.utc(params.value, "YYYY-MM-DD HH:mm:ss").local();
			  const formattedDate = parsedDate.format("DD MMM YYYY");
			  const formattedTime = parsedDate.format("h:mmA");
	  
			  return parsedDate.isValid() ? (
				<Box sx={{ display: "flex", flexDirection: 'column' }}>
				  <Typography variant="body2">{formattedDate}</Typography>
				  <Typography variant="body2">{formattedTime}</Typography>
				</Box>
			  ) : (
				<Typography variant="body2">--/--/----</Typography>
			  );
			},
		  },
		{
			field: "package_name",
			headerName: "Package Name",
			flex: 1,
			minWidth: 300,
			resizable: false,
			renderCell: (params) => {
				const { package_type } = params.row;

				const statusStyle = statusStyles[package_type?.toLowerCase()]; // Use optional chaining (?.) to avoid the error

				return (
					<>
						<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}>
							<Typography
								variant="body1"
								sx={{
									fontSize: '14px',
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}}
							>
								{params.value}
							</Typography>
							{/* <Chip
								label={capitalizeFirstLetter(package_type)}
								size="small"
								variant="outlined"
								sx={{
									// height: statusStyle?.height,
									// backgroundColor: statusStyle?.backgroundColor,
									// color: statusStyle?.color,
									// borderRadius: statusStyle?.borderRadius,
									// border: statusStyle?.border,

									// '& .MuiChip-label': {
									// 	textAlign:"center",
									// 	whiteSpace: 'normal',
									//   },
									maxWidth: 'fit-content'
								}}
							/> */}
							<CreateChip
								chipType={capitalizeFirstLetter(package_type)}
								size="small"
								sx={{
									// height: statusStyle?.height,
									backgroundColor: statusStyle?.backgroundColor,
									// color: statusStyle?.color,
									// borderRadius: statusStyle?.borderRadius,
									// border: statusStyle?.border,

									// '& .MuiChip-label': {
									// 	textAlign:"center",
									// 	whiteSpace: 'normal',
									//   },
									maxWidth: 'fit-content'
								}}
							/>
						</Box>
					</>
				);
			},
		},
		{ field: "billing_name", headerName: "Billing Name", flex: 1.5, minWidth: 150, resizable: false, },
		{
			field: "total", headerName: "Amount", flex: 1, minWidth: 100, resizable: false,
			renderCell: (params) => (
				<>
					{config.module === 'lister' ? <Box>
						<Box style={{ display: "flex", alignItems: "center", marginLeft: "16px" }}>
							${params.row.off_total_amount} <br />
						</Box>
						<Typography variant="caption">
							of total ${params.value}
						</Typography>
					</Box>
						:
						<Box style={{ display: "flex", alignItems: "center", marginLeft: "16px" }}>
							${params.value} <br />
						</Box>
					}
				</>
			),

		},


		{
			field: "payment_status",
			minWidth: 90,
			headerName: "Status",
			flex: 1,
			resizable: false,
			renderCell: (params) => {
				// console.log("paarams", statusStyles)
				const { payment_status, payment_status_caption } = params.row;

				const statusStyle = statusStyles[payment_status?.toLowerCase()]; // Use optional chaining (?.) to avoid the error
				return (
					<>

						{/* <Chip
							label={payment_status_caption}
							size="small"
							sx={{
								height: statusStyle?.height,
								backgroundColor: statusStyle?.backgroundColor,
								color: statusStyle?.color,
								borderRadius: statusStyle?.borderRadius,
								border: statusStyle?.border,

								// '& .MuiChip-label': {
								// 	textAlign:"center",
								// 	whiteSpace: 'normal',
								//   },
							}}
						/> */}
						<CreateChip
							chipType={payment_status_caption}
							caption={payment_status_caption}
							size="small"

							sx={{
								height: statusStyle?.height,
								backgroundColor: statusStyle?.backgroundColor,
								color: statusStyle?.color,
								borderRadius: statusStyle?.borderRadius,
								border: statusStyle?.border,

								// '& .MuiChip-label': {
								// 	textAlign:"center",
								// 	whiteSpace: 'normal',
								//   },
							}}
						/>
					</>
				);
			},
		},

		{
			field: "id",
			headerName: "Action",
			flex: 0.5,
			resizable: false,
			minWidth: 80,
			renderCell: (params) => {
				// console.log(params)
				return (
					<Link to={`/transactiondetails/${params.row.transaction_seq}`}>
						<Button
							size="small"
							variant="outlined"
							label={params.value}
							sx={{ p: 0 }}
						>
							View
						</Button>
					</Link>
				);
			},
		},
	];


	let nextId = 1;
	const generateUniqueId = () => {
		return nextId++;
	};

	// Conditional mapping based on whether 'transaction' is not empty
	const transactionslist =
		transaction.length > 0
			? transaction.map((transactions) => ({
				id: generateUniqueId(), // Generate a unique ID
				...transactions, // Include the rest of the transaction data
			}))
			: [];



	const statusStyles = {
		s: { height: "auto", backgroundColor: "#26C77A", color: "white", borderRadius: 4 },
		f: { height: "auto", backgroundColor: "#e94a4a", color: "white", borderRadius: 4 },
		a: { height: "auto", backgroundColor: "#FF962D", color: "white", borderRadius: 4, border: "1px solid #FF962D" },
		r: { height: "auto", backgroundColor: "#FFFFFF", color: "#26C77A", borderRadius: 4, border: "1px solid #26C77A" },
		c: { height: "auto", backgroundColor: "#FFFFFF", color: "#26C77A", borderRadius: 4, border: "1px solid #26C77A" },
		i: { height: "auto", backgroundColor: "#FFFFFF", color: "#FF962D", borderRadius: 4, border: "1px solid #FF962D" },
		rc: { height: "auto", backgroundColor: "#C5C5C7", color: "white", borderRadius: 4 },
		rf: { height: "auto", backgroundColor: "#C5C5C7", color: "white", borderRadius: 4 },
		rp: { height: "auto", backgroundColor: "#C5C5C7", color: "white", borderRadius: 4 },
		ra: { height: "auto", backgroundColor: "#C5C5C7", color: "white", borderRadius: 4 },
	};

	const SkeletonStyle = { borderRadius: theme => `${theme.card.borderRadius}`, height: 56, mb: 2, ml: 1, mr: 1 }

	return (
		<Box>
			{
				loading && !toggle ?
					<Box>
						<Box p={3}>
							{/* Header Section */}

							{/* Filter Options */}
							<Box display="flex" gap={2} mb={2} sx={{ flexWrap: 'wrap' }}>
								<Skeleton variant="rectangular" sx={{ width: { xs: 125, sm: 125, md: 200 }, borderRadius: '6px' }} height={40} />
								<Skeleton variant="rectangular" sx={{ width: { xs: 125, sm: 125, md: 200 }, borderRadius: '6px' }} height={40} />
								<Skeleton variant="rectangular" sx={{ width: { xs: 125, sm: 125, md: 200 }, borderRadius: '6px' }} height={40} />
							</Box>

							{/* Transactions Table */}
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											{[
												"Booking ID",
												"Date",
												"Package Name",
												"Billing Name",
												"Amount",
												"Status",
												"Action",
											].map((header, index) => (
												<TableCell key={index}>
													<Skeleton width="80px" />
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{Array.from({ length: 7 }).map((_, index) => (
											<TableRow key={index} sx={{ verticalAlign: 'top' }}>
												<TableCell>
													<Skeleton width="120px" />
												</TableCell>
												<TableCell>
													<Skeleton width="100px" />
												</TableCell>
												<TableCell>
													<Skeleton width="150px" />
													<Skeleton
														variant="rectangular"
														width="70px"
														height="20px"
														sx={{ mt: 0.5, borderRadius: 4 }}
													/>
												</TableCell>
												<TableCell>
													<Skeleton width="100px" />
												</TableCell>
												<TableCell>
													<Skeleton width="60px" />
												</TableCell>
												<TableCell>
													<Skeleton
														variant="rectangular"
														width="70px"
														height="24px"
														sx={{ borderRadius: 4 }}
													/>
												</TableCell>
												<TableCell>
													<Skeleton
														variant="rectangular"
														width="64px"
														height="30px"
														sx={{ borderRadius: 2 }}
													/>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
					</Box>
					:
					empty(transaction) && !toggle ? <Box sx={{ p: { xs: 2, sm: 2, md: 3, lg: 3 } }}>
						<TableNoData
							header="There are no transactions yet."
							title="You can track all transactions and payments here."
							buttonLink={config.module === 'lister' ? tableNoDataValues?.link : "/search"}
							buttonText={config.module === 'lister' ? tableNoDataValues?.buttonText : "Start Exploring"}
							image="/assets/images/placeholders/transactions_zero_img.svg"
							buttonClick={() => { config.module === 'lister' && setOpen(true) }}
						/>
					</Box> :
						<Box>
							<Box
								sx={{
									p: { xs: 2, sm: 2, md: 3, lg: 3 },
									display: "flex",
									gap: 1,
									alignItems: "center",
									flexWrap: "wrap", // Allow content to wrap on smaller screens
									justifyContent: 'flex-start'
								}}
							>
								<TextField
									value={filterConfig.search}
									size="small"

									onInput={(e) => {
										setFilterConfig({ ...filterConfig, search: e.target.value });
									}}
									placeholder="Search"
									onChange={handleSearch}
									sx={{
										borderRadius: 1.5, paddingInline: 0,
										width: isMobile ? "45%" : "204px",
										"& .MuiOutlinedInput-input": {
											paddingInline: 0,
											fontSize: "16px !important"
										},
										"& .MuiOutlinedInput-root": {
											height: 42,
											pl: "11px"
										}, "& .MuiInputBase-root": {
											fontSize: "16px !important"
										}
									}}
									InputProps={{
										startAdornment: <InputAdornment position='start'><Icon.SearchOutlined sx={{ ml: 0, color: "grey900" }} /></InputAdornment>
									}}
								/>
								<Box sx={{ borderRadius: 1.5, width: isMobile ? "50%" : "204px" }}>
									<FormControl fullWidth>
										<Select
											size="small"
											labelId="wishlist_sort_label"
											sx={{ p: 0, height: 42, color: "var(--grey600)" }}
											id="wishlist_sort"
											name="wishlist_sort"
											value={filterConfig.sort}
											onChange={(e) => {
												setFilterConfig({ ...filterConfig, sort: e.target.value });
											}}

										>

											<MenuItem value="R">Recent </MenuItem>
											<MenuItem value="O">Oldest </MenuItem>
											<MenuItem value="L">Low to High Amount</MenuItem>
											<MenuItem value="H"> High to Low Amount</MenuItem>
											{/* <MenuItem value="LA">Low to High Acre </MenuItem> */}
											<MenuItem value="PA">Failed transactions first</MenuItem>
											<MenuItem value="PD">Refund transactions first </MenuItem>
										</Select>
									</FormControl>
								</Box>
								<Box sx={{ minWidth: "20ch", borderRadius: 1.5 }}>
									<FormControl fullWidth sx={{ "& .MuiInputBase-root": { height: '42px' } }}>
										<InputLabel id="booking_search" size="small">
											Type
										</InputLabel>
										<Select
											labelId="booking_search"
											id="wishlist_sort"
											name="wishlist_sort"
											value={type}
											onChange={(e) => {
												setType(e.target.value);
												setToggle(true)
											}}
											size="small"
											label="tYPE"
										>
											<MenuItem value={"all"}>All</MenuItem>
											<MenuItem value={ACCESS.value}>{ACCESS.string}</MenuItem>
											<MenuItem value={EXPERIENCE.value}>{EXPERIENCE.string}</MenuItem>
										</Select>
									</FormControl>
								</Box>
							</Box>

						</Box>
			}
			{empty(transaction) && toggle && <Box sx={{ borderTop: "1px solid var(--grey500)", minHeight: "250px", textAlign: 'center', paddingTop: "100px", }}><Typography color='var(--grey900)' variant="body1" sx={{ fontWeight: 700 }}>No results found</Typography></Box>}


			{!empty(transaction) && <Box sx={{
				"& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell": {
					display: 'flex',
					alignItems: 'flex-start',
					py: 2
				}
			}}>
				<DataGridPremium
					rows={transactionslist}
					columns={columns}
					rowHeight={80}
					scrollbar={false}
					headerClassName="custom-header" // Optional, apply class to the entire header
					disableColumnReorder={true}
					disableMultipleRowSelection={true}
					disableRowSelectionOnClick={true}
					disableColumnMenu={true}
					rowSpacingType="border"
					hideFooter={true}
					hideScrollbar={true}
				// hideFooter={true}

				/>
				<Pagination totalRecords={totalRecords} setRowsPerPage={setRowsPerPage} setCurrentPage={setPage} currentPage={page} />
			</Box>}

		</Box>

	)
};
export default Transactiontable;