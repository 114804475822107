import { Avatar, Box, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CommonContext } from "../../../../store/context/commonContext";
import { UserAuth } from "../../../../store/context/userContext";
import config from "../../../../config";
import { decodeHTML, extractDataFromArray } from "../../../../utils/common";
import moment from "moment";
import { MessageContext } from "../../../../store/context/messageContext";
import MessageChip from "../MessageChip"

const ConnectionList = ({
  setResetScroll,
  fetchUserMessages,
  conversation,
  setActive,
  i,
  active,
  setSearch,
  fetchConnections,
  setLoadingUser,
  isLastConnection,
  connections
}) => {

  const navigate = useNavigate();
  const location = useLocation();
  const param = useParams();
  const [toggle, setToggle] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [seqUser, setSeqUser] = useState('')
  const [conversationSeq, setConversationSeq] = useState()
  let isToday
  const { formattedDate, formattedTime } = formatDateTime(conversation);
  const [listerName, setListerName] = useState('')
  const [searchParams, setSearchParams] = useSearchParams();

  let listerSeqFromUrl = searchParams.get("listerSeq");

  const { setLastListReadMessageSeq, setIntervalTime, isMobile, setIsMessageDashboard, setIsShowMessages, setIsProfile, setSenderSeqToSendChat, scrollableDivRef, setToBottom, setSenderTypeToSendChat, seqValue, setUserType, userData, listerSeqForAdmin, setListerSeqForAdmin } = MessageContext();


  useEffect(() => {
    if (location.pathname === `/chat/${extractDataFromArray(conversation, ['from', 'seq'], '') === seqValue ? extractDataFromArray(conversation, ['to', 'fname'], '') : extractDataFromArray(conversation, ['from', 'fname'], '')}` && !selectedUser) {
      navigate(`/chat/${extractDataFromArray(conversation, ['from', 'seq'], '') === seqValue ? extractDataFromArray(conversation, ['to', 'fname'], '') : extractDataFromArray(conversation, ['from', 'fname'], '')}`);
    }
    setLoadingUser(false)
  }, [])


  useEffect(() => {
    setSeqUser(extractDataFromArray(conversation, ['conversation', 'seq'], ''))
  }, [seqUser])



  function formatDateTime(conversation) {
    const dateTime = extractDataFromArray(conversation, ['create_time'], '');
    let local_date = moment.utc(dateTime).local().format('YYYY-MM-DD HH:mm:ss');

    const [date, time] = local_date.split(" ");

    const currentDate = new Date();
    isToday = date === currentDate.toISOString().split("T")[0];

    const [hours, minutes, seconds] = time.split(":");

    const formattedTime = isToday
      ? `${parseInt(hours, 10) % 12 || 12}:${minutes}${parseInt(hours, 10) >= 12 ? " PM" : " AM"}`
      : time;

    const providedDate = moment(date);
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const formattedDate =
      providedDate.toISOString().split("T")[0] === yesterday.toISOString().split("T")[0]
        ? "Yesterday"
        : providedDate.format('MM/DD/YY');

    return { formattedDate, formattedTime };
  }



  let messageCount;

  switch (true) {
    case extractDataFromArray(conversation, ['unread_message_count'], '') == 0:
      messageCount = 0;
      break;
    case extractDataFromArray(conversation, ['unread_message_count'], '') > 9:
      messageCount = '9+';
      break;
    default:
      messageCount = extractDataFromArray(conversation, ['unread_message_count'], '');
  }

  const [listerSeqFromConversationClick, setListerSeqFromConversationClick] = useState('')


  const handleConversationClick = () => {
    setIntervalTime(300)
    setSeqUser(conversation.conversation.seq)
    setConversationSeq(extractDataFromArray(conversation, ['conversation', 'seq'], ''))

    if (conversation && config.module === 'admin') {  // Getting lister seq when clicked on conversation for admin portal getUserMessages api
      // console.log("here");

      const listerObj = conversation.from?.type === 'L' ? conversation.from : conversation.to;

      if (listerObj?.seq) {
        setListerSeqForAdmin(`${listerObj.seq}`);
        setListerSeqFromConversationClick(`${listerObj.seq}`)
      }
    }

    fetchUserMessages(extractDataFromArray(conversation, ['conversation', 'seq'], ''))
    setResetScroll(true)
    setLastListReadMessageSeq(conversation?.seq)

    setTimeout(() => {
      fetchConnections()
      setIntervalTime(20000)
      setSearch("")
      scrollableDivRef?.current?.scrollIntoView({ // Added this to avoid entire page scroll when last message is shown using scrollableDivRef 
        behavior: 'instant',
        block: 'nearest',
        inline: 'start'
      });
    }, 400)

    setSenderSeqToSendChat(extractDataFromArray(conversation, ['conversation', 'seq']))

    if (config.module === 'lister') {
      setSenderTypeToSendChat("G");
    } else {
      setSenderTypeToSendChat("L");
    }

    setToggle(false);

    if (isMobile) {
      setIsMessageDashboard(false);
      setIsShowMessages(true);
      setIsProfile(false);
    }

    setToBottom(true)

    if (config.module === 'admin' && conversation) {  // Adding lister seq in url for admin portal when clicked on item from left side list
      const listerObj = conversation.from?.type === 'L' ? conversation.from : conversation.to;
      const listerSeq = listerObj?.seq;

      if (listerObj?.seq) {
        setListerSeqForAdmin(`${listerObj.seq}`);
      }
      const baseUrl = `/chat/${extractDataFromArray(conversation, ['conversation', 'fname'], '')}/${extractDataFromArray(conversation, ['conversation', 'seq'], '')}`;
      const location = `${baseUrl}?listerSeq=${listerSeq}`;

      setTimeout(() => {
        navigate(location, { replace: true }); // Replace instead of pushing new history
      }, 300);



    } else {
      // console.log("HEREEEEEEEEEEEEEEEEE");

      navigate(`/chat/${extractDataFromArray(conversation, ['conversation', 'fname'], '')}/${extractDataFromArray(conversation, ['conversation', 'seq'], '')}`);
    }
  };

  useEffect(() => {
    // Check if connections is an array and param exists
    if (!Array.isArray(connections) || !param) {
      setUserType('');
      return;
    }

    // Find the matching conversation object
    const matchingConnection = connections?.find(connection =>
      connection?.conversation && connection?.conversation?.seq === param?.seq
    );

    // Set userType safely
    if (matchingConnection && matchingConnection?.conversation) {
      setUserType(matchingConnection?.conversation?.type || '');
    } else {
      setUserType('');
    }


    if (conversation) { // Added it to show lister name under user name in list for admin portal


      const listerSeqObj = connections[0]?.from?.type === 'L' ? connections[0]?.from : connections[0]?.to;
      // console.log("listerSeqObj", listerSeqObj);

      // setListerSeqForAdmin(listerSeqObj?.seq);

      const listerObj = conversation.from?.type === 'L' ? conversation.from : conversation.to;

      if (listerObj?.fname && listerObj?.lname) {
        setListerName(`${listerObj.fname} ${listerObj.lname}`);
      }
    }
  }, [connections, param]);


  const timerRef = useRef(null);

  // Added below logic to open first conversation when chat page is loaded for the first time

  useEffect(() => {
    // Skip if connections is empty
    if (!connections?.length || isMobile) return;

    // Clear any existing timer
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Set a new timer - this will only execute after connections stops updating
    timerRef.current = setTimeout(() => {
      // Check if this is first visit to Chat page
      const isFirstVisit = !localStorage.getItem('chatPageVisited');

      // Only proceed if it's first visit
      if (isFirstVisit) {
        localStorage.setItem('chatPageVisited', 'true');

        // console.log("First load");
        const listerSeqObj = connections[0]?.from?.type === 'L' ? connections[0]?.from : connections[0]?.to; // Added this to save lister seq and load first user messages when page is opened first time in admin portal
        setListerSeqForAdmin(listerSeqObj?.seq);
        setListerSeqFromConversationClick(listerSeqObj?.seq)
        // console.log("listerSeqObj", listerSeqObj);

        setIntervalTime(300);
        setSeqUser(connections[0].conversation.seq);
        setConversationSeq(extractDataFromArray(connections, [0, 'conversation', 'seq'], ''));
        fetchUserMessages(extractDataFromArray(connections, [0, 'conversation', 'seq'], ''));
        setResetScroll(true);
        setLastListReadMessageSeq(connections[0]?.seq);


        const checkUrlParams = () => { // Added this to highlight first chat when chat page is opened
          const urlParams = new URLSearchParams(window.location.search);

          const urlSeq = urlParams.get('seq');
          const urlListerSeq = urlParams.get('listerSeq');

          if (urlSeq || urlListerSeq) {
            if (urlSeq) {
              // Update param with new seq
              param.seq = urlSeq;
            }

            if (urlListerSeq) {
              listerSeqFromUrl = urlListerSeq;
            }
          } else {
            // Retry if params not found
            setTimeout(checkUrlParams, 100);
          }
        };

        checkUrlParams();

        setTimeout(() => {
          fetchConnections();
          setIntervalTime(20000);
          setSearch("");
          scrollableDivRef?.current?.scrollIntoView({
            behavior: 'instant',
            block: 'nearest',
            inline: 'start'
          });
        }, 400);

        setSenderSeqToSendChat(extractDataFromArray(connections, [0, 'conversation', 'seq']));

        if (config.module === 'lister') {
          setSenderTypeToSendChat("G");
        } else {
          setSenderTypeToSendChat("L");
        }

        setToggle(false);

        if (isMobile) {
          setIsMessageDashboard(false);
          setIsShowMessages(true);
          setIsProfile(false);
        }

        setToBottom(true);

        if (config.module === 'admin' && listerSeqObj?.seq) { // Added this logic to add lister seq in url when chat page is loaded initially
          // console.log("SSSSSSSSSSSSS");

          navigate({
            pathname: `/chat/${extractDataFromArray(connections, [0, 'conversation', 'fname'], '')}/${extractDataFromArray(connections, [0, 'conversation', 'seq'], '')}`,
            search: `?listerSeq=${listerSeqObj.seq}`
          });
        } else {
          // console.log("FFFFFFFFFFFFFFFFF");
          navigate(`/chat/${extractDataFromArray(connections, [0, 'conversation', 'fname'], '')}/${extractDataFromArray(connections, [0, 'conversation', 'seq'], '')}`);
        }
      }
    }, 400); // Wait 400ms after the last connections update

    // Cleanup timer when component unmounts or connections changes again
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [connections, isMobile]);

  // Cleanup effect to remove localStorage flag when leaving Chat page
  useEffect(() => {
    return () => {
      if (!window.location.pathname.startsWith('/chat')) {
        localStorage.removeItem('chatPageVisited');
      }
    };
  }, []);



  // useEffect(() => {
  //   console.log('config.module:', config.module);
  //   console.log('param.seq:', param.seq);
  //   console.log('extractedSeq:', extractDataFromArray(conversation, ['conversation', 'seq'], ''));
  //   console.log('listerSeqFromUrl:', listerSeqFromUrl);
  //   console.log('listerSeqFromConversationClick:', listerSeqFromConversationClick);
  //   // console.log("listerSeqFromUrl", listerSeqFromUrl);
  //   // console.log("listerSeqFromConversationClick", listerSeqFromConversationClick);

  // }, [listerSeqFromConversationClick])

  useEffect(() => { // Added this to highligh preiously loaded chat on page refresh
    const isPageRefreshed = sessionStorage.getItem('isPageRefreshed') === 'true';

    if (isPageRefreshed) {
      const urlParams = new URLSearchParams(location.search);

      const urlSeq = urlParams.get('seq');
      const urlListerSeq = urlParams.get('listerSeq');

      if (urlSeq) {
        param.seq = urlSeq;
      }

      if (urlListerSeq) {

        listerSeqFromUrl = urlListerSeq;
        setListerSeqFromConversationClick(prevState => {
          // Force update even if value seems the same
          return urlListerSeq;
        })
      }

      // Clear the flag after processing
      sessionStorage.removeItem('isPageRefreshed');
    }

    // Set flag before potential refresh
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('isPageRefreshed', 'true');
    });
  }, []);

  const isHighlighted = ( // Added this condition to scroll into view selected chat message in left side list
    (conversation.from.type === 'L' && conversation.from.seq === listerSeqFromUrl) ||
    (conversation.to.type === 'L' && conversation.to.seq === listerSeqFromUrl)
  ) && (
      (conversation.from.type === 'G' && conversation.from.seq === param.seq) ||
      (conversation.to.type === 'G' && conversation.to.seq === param.seq)
    );

  useEffect(() => { // Added this condition to scroll into view selected chat message in left side list
    if (isHighlighted) {
      setActive(conversation);
      // Find the element with the highlight class and scroll to it
      const highlightedElement = document.querySelector('.highlighted-connection');
      if (highlightedElement) {
        highlightedElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
      }
    }
  }, [isHighlighted, conversation, setActive]);

  return (
    <Box
      className={`connection-item ${isHighlighted ? 'highlighted-connection' : ''}`}
    >

      <Box
        key={i}
        sx={
          isMobile
            ? {
              display: "flex",
              padding: 2,
              gap: 1.25,
              alignItems: "flex-start",
              borderBottom: "1px solid #E5E7EB",
              cursor: "pointer",
              backgroundColor: "white",
              marginBottom: isLastConnection ? "67px" : '0px'
            }
            : (config.module === 'admin'
              ? (
                (
                  (conversation.from.type === 'L' && conversation.from.seq === listerSeqFromUrl) ||
                  (conversation.to.type === 'L' && conversation.to.seq === listerSeqFromUrl)
                ) &&
                (
                  (conversation.from.type === 'G' && conversation.from.seq === param.seq) ||
                  (conversation.to.type === 'G' && conversation.to.seq === param.seq)
                )
              )
              : param.seq === extractDataFromArray(conversation, ['conversation', 'seq'], ''))
              ? {
                display: "flex",
                padding: 2,
                gap: 1.25,
                alignItems: "flex-start",
                borderBottom: "1px solid #E5E7EB",
                cursor: "pointer",
                backgroundColor: "#F5F5F6",
              }
              : {
                display: "flex",
                padding: 2,
                gap: 1.25,
                alignItems: "flex-start",
                borderBottom: "1px solid #E5E7EB",
                cursor: "pointer",
                backgroundColor: "white",
              }
        }
        onClick={() => {
          handleConversationClick()
        }}
      >
        <Box>
          <Avatar
            alt="Remy Sharp"
            src={
              extractDataFromArray(conversation, ['conversation', 'profile_pic'], '')
            }
            sx={{ width: 40 }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: 0.375,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle1" className="ellipsis" sx={{ fontWeight: 600 }}>
              {decodeHTML(extractDataFromArray(conversation, ['conversation', 'fname'], '') + " " + extractDataFromArray(conversation, ['conversation', 'lname'], ''))}
            </Typography>
            <Typography variant="caption" sx={{ color: "#85878A" }}>
              {isToday ? formattedTime : formattedDate}
            </Typography>
          </Box>
          {
            config.module === 'admin' && listerName &&
            <Typography
              variant="caption"
              sx={{
                color: "#85878A",
                width: 200,
                whiteSpace: "nowrap",
                overflow: "hidden ",
                textOverflow: "ellipsis !important",
              }}
            >
              {listerName}
            </Typography>
          }
          <MessageChip messageType={extractDataFromArray(conversation, ['messages', 'message_type'], '')} />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="caption"
              sx={conversation.status === "R" ? {

                color: "#85878A",
                width: 225,
                whiteSpace: "nowrap",
                overflow: "hidden ",
                textOverflow: "ellipsis !important"
              }
                : {

                  color: "#85878A",
                  width: 200,
                  whiteSpace: "nowrap",
                  overflow: "hidden ",
                  textOverflow: "ellipsis !important",
                }}
            >
              {extractDataFromArray(conversation, ['messages', 'message'], '')}
            </Typography>

            {extractDataFromArray(conversation, ['unread_message_count'], '') == 0 ?
              ""
              :
              <Box
                sx={{
                  fontSize: "12px",
                  backgroundColor: "#26C77A",
                  borderRadius: "50%",
                  width: 21,
                  height: 21,
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {messageCount}
              </Box>
            }

          </Box>

        </Box>
      </Box>

    </Box>
  );
};

export default ConnectionList;
